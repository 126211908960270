import React from "react";
import { Helmet } from "react-helmet-async";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import { Paper, Typography } from "@mui/material";
import Login from "../components/Login/Login";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8),
  },
  logo: {
    width: 200,
    height: "auto",
    marginBottom: theme.spacing(2),
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  version: {
    color: "#66788a",
    fontSize: 14,

    "& code": {
      fontSize: 12,
    },
  },
}));

const viewTitle = "Přihlášení";

const LoginView = () => {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>{viewTitle} — Actuaria</title>
      </Helmet>
      <Container className={classes.container} maxWidth={"sm"}>
        <Paper elevation={0}>
          <Box p={3}>
            <Login />
          </Box>
        </Paper>

        <Typography
          className={classes.version}
          sx={{ textAlign: "center", mt: 2 }}
          variant="body1"
        >
          Actuaria <code>{process.env.REACT_APP_VERSION}</code>
        </Typography>
      </Container>
    </>
  );
};

export default LoginView;
