import { textFieldMargin, textFieldVariant } from "../../../theme/theme";
import React from "react";
//import { DatePicker } from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers";
import { Box, TextField } from "@mui/material";

const dateIsValid = (date) => {
  return !Number.isNaN(new Date(date).getTime());
};

const FormFieldDate = (props) => {
  const { name, onChange, value, ...rest } = props;

  return (
    <Box>
      <DatePicker
        autoOk={true}
        clearable={true}
        format={"D. M. yyyy"}
        cancelLabel={"Storno"}
        clearLabel={"Smazat"}
        okLabel={"Ok"}
        value={!!value ? value : null}
        onChange={(value) => {
          if (!value) {
            onChange({
              target: {
                type: "date",
                name,
                value: null,
              },
            });
          } else {
            if (dateIsValid(value)) {
              onChange({
                target: {
                  type: "date",
                  name,
                  value: new Date(value).toISOString(),
                },
              });
            }
          }

          // const event = {
          //   target: {
          //     type: "date",
          //     name,
          //     value: !!value ? new Date(value).toISOString() : null,
          //   },
          // };
          // onChange(event);
        }}
        renderInput={(params) => (
          <TextField
            variant={textFieldVariant}
            margin={textFieldMargin}
            {...params}
          />
        )}
        {...rest}
      />
    </Box>
  );
};

export default FormFieldDate;
