import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { Box } from "@mui/material";
import TagsInput from "../TagsInput/TagsInput";
import { useDispatch, useSelector } from "react-redux";
import { USER_EMAIL_SET } from "../../constants/actionTypes";

LicenseInfo.setLicenseKey(
  "x0jTPl0USVkVZV0SsMjM1kDNyADM5cjM2ETPZJVSQhVRsIDN0YTM6IVREJ1T0b9586ef25c9853decfa7709eee27a1e"
);

const DataTableSearch = (props) => {
  const { onSubmit } = props;

  const dispatch = useDispatch();
  const defaultTags = useSelector((state) => {
    const userEmail = state.app.userEmail;
    if (window.location.pathname === "/payment" && userEmail !== "") {
      return [userEmail];
    }
    return [];
  });

  const handleSubmit = (tags) => {
    if (tags.length === 0) {
      dispatch({
        type: USER_EMAIL_SET,
        payload: {
          userEmail: "",
        },
      });
    }
    onSubmit(tags);
  };

  return (
    <Box
      sx={{
        mt: 2,
        mb: 2,
      }}
    >
      <TagsInput
        selectedTags={handleSubmit}
        tags={defaultTags}
        label={"Vyhledávání"}
        fullWidth={true}
      />
    </Box>
  );
};

const DataTable = (props) => {
  const { rows, columns, ...rest } = props;

  const [search, setSearch] = React.useState([]);

  const handleSearchSubmit = (search) => {
    setSearch(search);
  };

  const filteredRows = rows.filter((row) => {
    if (search.length === 0) {
      return true;
    }
    let fulltext = "";
    columns.forEach((column) => {
      if (column.hasOwnProperty("valueGetter")) {
        fulltext = fulltext + column.valueGetter({ row });
      } else {
        fulltext = fulltext + row[column.field];
      }
      if (row.hasOwnProperty("values")) {
        Object.values(row.values).forEach((value) => {
          fulltext = fulltext + value;
        });
      }
    });
    return search.every((s) => {
      return fulltext.toLocaleLowerCase().includes(s.toLocaleLowerCase());
    });
  });

  return (
    <>
      <DataTableSearch onSubmit={handleSearchSubmit} />
      <DataGrid columns={columns} rows={filteredRows} {...rest} />
    </>
  );
};

export default DataTable;
