import React from "react";
import { formFieldTypeNumber } from "../../constants/enum";
import { FORM_MODE_CREATE, FORM_MODE_UPDATE } from "../../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFormByFormNameIsOpen,
  selectFormByFormNameMode,
} from "../../selectors/selectors";
import {
  TYPE_CPD_YEAR,
  useCreateCpdYearMutation,
  useGetCpdHourTypeAllQuery,
  useUpdateCpdYearMutation,
} from "../../api/api";
import { appSnackbarAdd, formSet } from "../../actions/appActions";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { AddCircle, Save } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import FormField from "../Form/FormField";
import { store } from "../../store";

const formName = TYPE_CPD_YEAR;

const getFormFields = (mode, { hourTypes }) => {
  const fields = {};
  fields.title = {
    formFieldType: formFieldTypeNumber,
    label: "Rok",
    fullWidth: true,
  };

  hourTypes
    .filter((ht) => ht.useInRequirement)
    .forEach((ht) => {
      fields["minRequirement_" + ht.id] = {
        formFieldType: formFieldTypeNumber,
        label: "Minimální požadavek: " + ht.title,
        fullWidth: true,
      };
    });

  return fields;
};

const CpdYear = () => {
  const dispatch = useDispatch();

  const open = useSelector((state) =>
    selectFormByFormNameIsOpen(state, { formName })
  );
  const mode = useSelector((state) =>
    selectFormByFormNameMode(state, { formName })
  );

  const hourTypesData = useGetCpdHourTypeAllQuery();

  const hourTypes = hourTypesData.data?.["hydra:member"] || [];

  const [create, createResult] = useCreateCpdYearMutation();
  const [update, updateResult] = useUpdateCpdYearMutation();

  const handleClose = () => {
    dispatch(formSet(formName, null));
  };

  const [errors, setErrors] = React.useState({});

  const handleSubmit = () => {
    const form = { ...store.getState().app.forms[formName] };

    const body = {
      code: form.title,
      title: form.title,
      minimalRequirements: Object.keys(form)
        .filter((key) => {
          return key.startsWith("minRequirement_");
        })
        .map((key) => {
          const ids = key.split("_");
          return {
            type_id: ids[1],
            value: form[key] * 1,
          };
        }),
    };

    if (mode === FORM_MODE_CREATE) {
      create({ body: body });
    }
    if (mode === FORM_MODE_UPDATE) {
      body.id = form.id;
      update({ body: body, id: body.id });
    }
  };

  const disabled = updateResult.isLoading || createResult.isLoading;

  React.useEffect(() => {
    if (
      !!createResult &&
      !createResult.isLoading &&
      !createResult.isError &&
      createResult.isSuccess
    ) {
      dispatch(
        appSnackbarAdd({
          message: "Roční minimální požadavky byly vytvořeny.",
          variant: "success",
        })
      );
      handleClose();
    }
    if (
      !!createResult &&
      !createResult.isLoading &&
      createResult.isError &&
      !createResult.isSuccess
    ) {
      dispatch(
        appSnackbarAdd({
          message: "Roční minimální požadavky nebyly vytvořeny.",
          variant: "error",
        })
      );
    }
  }, [createResult]);

  React.useEffect(() => {
    if (
      !!updateResult &&
      !updateResult.isLoading &&
      !updateResult.isError &&
      updateResult.isSuccess
    ) {
      dispatch(
        appSnackbarAdd({
          message: "Roční minimální požadavky byly upraveny.",
          variant: "success",
        })
      );
      handleClose();
    }
    if (
      !!updateResult &&
      !updateResult.isLoading &&
      updateResult.isError &&
      !updateResult.isSuccess
    ) {
      dispatch(
        appSnackbarAdd({
          message: "Roční minimální požadavky nebyly upraveny.",
          variant: "error",
        })
      );
    }
  }, [updateResult]);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {mode === FORM_MODE_CREATE
            ? "Vytvořit roční minimální požadavky"
            : "Upravit roční minimální požadavky"}
          <IconButton
            sx={(theme) => {
              return {
                position: "absolute",
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              };
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {open && (
            <>
              {Object.entries(getFormFields(mode, { hourTypes })).map((v) => {
                const [name, field] = v;
                return (
                  <FormField
                    key={name}
                    formName={formName}
                    name={name}
                    {...field}
                  />
                );
              })}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disableElevation
            startIcon={mode === FORM_MODE_CREATE ? <AddCircle /> : <Save />}
            disabled={disabled}
          >
            {mode === FORM_MODE_CREATE
              ? "Vytvořit roční minimální požadavky"
              : "Upravit roční minimální požadavky"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CpdYear;
