import { Box, Checkbox, FormControlLabel } from "@mui/material";
import React from "react";

const FormFieldCheckbox = (props) => {
  const { label, value, ...rest } = props;

  return (
    <Box>
      <FormControlLabel
        control={<Checkbox color={"primary"} checked={value} {...rest} />}
        label={label}
      />
    </Box>
  );
};

export default FormFieldCheckbox;
