import { createSelector } from "reselect";
import { FORM_MODE_CREATE, FORM_MODE_UPDATE } from "../constants/constants";

export const selectForms = (state) => {
  return state.app.forms;
};

export const selectFormByFormName = createSelector(
  [selectForms, (state, args) => args.formName],
  (forms, formName) => {
    return forms[formName];
  }
);

export const selectFormByFormNameIsOpen = createSelector(
  [selectFormByFormName],
  (form) => {
    return Boolean(form);
  }
);

export const selectFormByFormNameMode = createSelector(
  [selectFormByFormName],
  (form) => {
    return Boolean(form) && form.hasOwnProperty("id")
      ? FORM_MODE_UPDATE
      : FORM_MODE_CREATE;
  }
);

export const selectFormValueByFormNameAndFieldName = createSelector(
  [selectFormByFormName, (state, args) => args.name],
  (form, name) => {
    if (!form) {
      return null;
    }
    if (form.hasOwnProperty("values")) {
      if (form.values.hasOwnProperty(name)) {
        return form.values[name];
      }
    }
    if (form.hasOwnProperty(name)) {
      return form[name];
    }
    return null;
  }
);
