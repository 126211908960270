import { TextField } from "@mui/material";
import { textFieldMargin, textFieldVariant } from "../../../theme/theme";
import React from "react";

const FormFieldSingleLineText = (props) => {
  return (
    <TextField margin={textFieldMargin} variant={textFieldVariant} {...props} />
  );
};

export default FormFieldSingleLineText;
