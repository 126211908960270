export const compareFnBy = (prop) => {
  return (a, b) => {
    return a[prop] - b[prop];
  };
};

export const formatDate = (dtString = null) => {
  if (dtString == null) {
    return "";
  }
  const dt = new Date(dtString);
  return [dt.getFullYear(), dt.getMonth() + 1, dt.getDate()]
    .map((t) => {
      return t < 10 ? "0" + t : t;
    })
    .join("-");
};
