export const initialMemberField = {
  enabled: true,
  title: "",
  type: "singleLineText",
  memberGroups: [],
  visibility: "show",
  position: 0,
  options: [],
};

export const initialMember = {
  enabled: true,
  showInContacts: true,
  email: "",
  memberGroups: [],
  values: {},
};

export const initialMemberGroup = {
  enabled: true,
  code: "",
  title: "",
  description: "",
};

export const initialPaymentOrderType = {
  enabled: true,
  code: "",
  title: "",
};

export const initialPaymentV0 = {
  member: null,
  type: null,
  validFrom: "",
  validTo: "",
  paidAt: "",
  amount: "",
  status: "Předepsaná (před splatností)",
  variableSymbol: "",
  specificSymbol: "",
  note: "",
};

export const initialPayment = {
  payer: "",
  amount: "",
  variableSymbol: "",
  specificSymbol: "",
  note: "",
};

export const initialPaymentOrder = {
  member: null,
  type: null,
  year: "",
  amount: "",
  variableSymbol: "",
  specificSymbol: "",
  note: "",
};

export const initialPaymentPaymentOrder = {
  payment: null,
  paymentOrder: null,
  amount: null,
};

export const initialCpdEventCategory = {
  code: "",
  title: "",
};

export const initialCpdYear = {
  title: "",
};

export const initialCpdEvent = {
  code: "",
  title: "",
  dateFrom: null,
  dateTo: null,
  dateApproval: null,
  description: "",
  deleted: false,
  locked: false,
  organizator: "",
  speaker: "",
  outOfList: false,
  url: "",
  categoryId: null,
  yearId: null,
  createdByMemberId: null,
  createdByUserId: null,
};

export const initialCpdEventAttendee = {
  memberId: null,
  eventId: null,
  event: null,
  deleted: false,
  participationConfirmed: false,
};
