import React from "react";
import {
  formFieldTypeDate,
  formFieldTypeLongText,
  formFieldTypeNumber,
  formFieldTypeSingleLineText,
  formFieldTypeSingleSelect,
} from "../../constants/enum";
import { FORM_MODE_CREATE, FORM_MODE_UPDATE } from "../../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFormByFormNameIsOpen,
  selectFormByFormNameMode,
} from "../../selectors/selectors";
import {
  TYPE_PAYMENT_V0,
  useCreatePaymentV0Mutation,
  useGetMembersAllQuery,
  useGetPaymentOrderTypesAllQuery,
  useUpdatePaymentV0Mutation,
} from "../../api/api";
import { appSnackbarAdd, formSet } from "../../actions/appActions";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { AddCircle, Save } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import FormField from "../Form/FormField";
import { store } from "../../store";

const formName = TYPE_PAYMENT_V0;

const getFormFields = (mode, { members, paymentOrderTypes }) => {
  const fields = {};
  fields.member = {
    formFieldType: formFieldTypeSingleSelect,
    options: members.map((v) => {
      return v["@id"];
    }),
    getOptionValue: (option) => {
      return option;
    },
    getOptionLabel: (option) => {
      const index = members.findIndex((v) => v["@id"] === option);
      if (index > -1) {
        return members[index].email;
      }
      return option;
    },
    label: "Člen",
    fullWidth: true,
  };
  fields.type = {
    formFieldType: formFieldTypeSingleSelect,
    options: paymentOrderTypes.map((v) => {
      return v["@id"];
    }),
    getOptionValue: (option) => {
      return option;
    },
    getOptionLabel: (option) => {
      const index = paymentOrderTypes.findIndex((v) => v["@id"] === option);
      if (index > -1) {
        return paymentOrderTypes[index].title;
      }
      return option;
    },
    label: "Typ",
    fullWidth: true,
  };
  fields.validFrom = {
    formFieldType: formFieldTypeDate,
    label: "Od",
    fullWidth: true,
  };
  fields.validTo = {
    formFieldType: formFieldTypeDate,
    label: "Do",
    fullWidth: true,
  };
  fields.amount = {
    formFieldType: formFieldTypeNumber,
    label: "Částka",
    fullWidth: true,
  };
  fields.status = {
    formFieldType: formFieldTypeSingleSelect,
    options: [
      "Předepsaná (před splatností)",
      "Uhrazená",
      "Dlužná (po splatnosti)",
      "Přeplatek (nespárovaná)",
      "Stornovaná",
      "Odepsaná",
    ],
    getOptionValue: (option) => {
      return option;
    },
    getOptionLabel: (option) => {
      return option;
    },
    label: "Stav",
    fullWidth: true,
  };
  fields.paidAt = {
    formFieldType: formFieldTypeDate,
    label: "Zaplaceno",
    fullWidth: true,
  };
  fields.variableSymbol = {
    formFieldType: formFieldTypeSingleLineText,
    label: "VS",
    fullWidth: true,
  };
  fields.specificSymbol = {
    formFieldType: formFieldTypeSingleLineText,
    label: "SS",
    fullWidth: true,
  };
  fields.note = {
    formFieldType: formFieldTypeLongText,
    label: "Poznámka",
    fullWidth: true,
  };

  return fields;
};

const PaymentV0Detail = () => {
  const dispatch = useDispatch();

  const open = useSelector((state) =>
    selectFormByFormNameIsOpen(state, { formName })
  );
  const mode = useSelector((state) =>
    selectFormByFormNameMode(state, { formName })
  );

  const membersData = useGetMembersAllQuery();
  const paymentOrderTypesData = useGetPaymentOrderTypesAllQuery();

  const members = membersData.data?.["hydra:member"] || [];
  const paymentOrderTypes = paymentOrderTypesData.data?.["hydra:member"] || [];

  const [create, createResult] = useCreatePaymentV0Mutation();
  const [update, updateResult] = useUpdatePaymentV0Mutation();

  const handleClose = () => {
    dispatch(formSet(formName, null));
  };

  const [errors, setErrors] = React.useState({});

  // const validateForm = () => {
  //   const errors = {};
  //   let isValid = true;
  //   for (let memberField of memberFields) {
  //     if (
  //       memberField.required &&
  //       (!form.hasOwnProperty(memberField.id) ||
  //         form[memberField.id].trim() === "")
  //     ) {
  //       errors[memberField.id] = "Toto pole je povinné.";
  //       isValid = false;
  //     }
  //   }
  //   setErrors(errors);
  //
  //   return isValid;
  // };

  const handleSubmit = () => {
    const form = { ...store.getState().app.forms[formName] };

    form.oldId = "";
    if (form.paidAt === "" || form.paidAt === null) {
      form.paidAt = null;
    }

    if (mode === FORM_MODE_CREATE) {
      create({ body: form });
    }
    if (mode === FORM_MODE_UPDATE) {
      update({ body: form, id: form.id });
    }
  };

  const disabled = updateResult.isLoading || createResult.isLoading;

  React.useEffect(() => {
    if (
      !!createResult &&
      !createResult.isLoading &&
      !createResult.isError &&
      createResult.isSuccess
    ) {
      dispatch(
        appSnackbarAdd({
          message: "Platba byla vytvořena.",
          variant: "success",
        })
      );
      handleClose();
    }
    if (
      !!createResult &&
      !createResult.isLoading &&
      createResult.isError &&
      !createResult.isSuccess
    ) {
      dispatch(
        appSnackbarAdd({
          message: "Platba nebyla vytvořena.",
          variant: "error",
        })
      );
    }
  }, [createResult]);

  React.useEffect(() => {
    if (
      !!updateResult &&
      !updateResult.isLoading &&
      !updateResult.isError &&
      updateResult.isSuccess
    ) {
      dispatch(
        appSnackbarAdd({
          message: "Platba byla upravena.",
          variant: "success",
        })
      );
      handleClose();
    }
    if (
      !!updateResult &&
      !updateResult.isLoading &&
      updateResult.isError &&
      !updateResult.isSuccess
    ) {
      dispatch(
        appSnackbarAdd({
          message: "Platba nebyla upravena.",
          variant: "error",
        })
      );
    }
  }, [updateResult]);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {mode === FORM_MODE_CREATE ? "Vytvořit platbu" : "Upravit platbu"}
          <IconButton
            sx={(theme) => {
              return {
                position: "absolute",
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              };
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {open && (
            <>
              {Object.entries(
                getFormFields(mode, { members, paymentOrderTypes })
              ).map((v) => {
                const [name, field] = v;
                return (
                  <FormField
                    key={name}
                    formName={formName}
                    name={name}
                    {...field}
                  />
                );
              })}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disableElevation
            startIcon={mode === FORM_MODE_CREATE ? <AddCircle /> : <Save />}
            disabled={disabled}
          >
            {mode === FORM_MODE_CREATE ? "Vytvořit platbu" : "Upravit platbu"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PaymentV0Detail;
