import { TextField } from "@mui/material";
import { textFieldMargin, textFieldVariant } from "../../../theme/theme";
import React from "react";

const FormFieldLongText = (props) => {
  return (
    <TextField
      margin={textFieldMargin}
      variant={textFieldVariant}
      multiline={true}
      rows={4}
      {...props}
    />
  );
};

export default FormFieldLongText;
