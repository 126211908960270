import React from "react";
import {
  fieldTypes,
  fieldVisibilities,
  formFieldTypeCheckbox,
  formFieldTypeMultipleSelect,
  formFieldTypeSingleLineText,
  formFieldTypeSingleSelect,
} from "../../constants/enum";
import { FORM_MODE_CREATE, FORM_MODE_UPDATE } from "../../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFormByFormName,
  selectFormByFormNameIsOpen,
  selectFormByFormNameMode,
} from "../../selectors/selectors";
import {
  TYPE_MEMBER_FIELD,
  useCreateMemberFieldMutation,
  useGetMemberGroupsAllQuery,
  useUpdateMemberFieldMutation,
} from "../../api/api";
import { appSnackbarAdd, formSet } from "../../actions/appActions";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { AddCircle, Save } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import FormField from "../Form/FormField";
import { store } from "../../store";
import FormFieldSelectAdditionalRender from "../Form/FormField/additional/FormFieldSelectAdditionalRender";
import { v4 as uuidv4 } from "uuid";
import { FORM_UPDATE } from "../../constants/actionTypes";

const formName = TYPE_MEMBER_FIELD;

const MemberFieldDetail = () => {
  const dispatch = useDispatch();

  const open = useSelector((state) =>
    selectFormByFormNameIsOpen(state, { formName })
  );
  const mode = useSelector((state) =>
    selectFormByFormNameMode(state, { formName })
  );

  const memberGroupsData = useGetMemberGroupsAllQuery();

  const memberGroups = memberGroupsData.data?.["hydra:member"] || [];

  const form = useSelector((state) =>
    selectFormByFormName(state, { formName })
  );

  const [create, createResult] = useCreateMemberFieldMutation();
  const [update, updateResult] = useUpdateMemberFieldMutation();

  const handleClose = () => {
    dispatch(formSet(formName, null));
  };

  const [errors, setErrors] = React.useState({});

  // const validateForm = () => {
  //   const errors = {};
  //   let isValid = true;
  //   for (let memberField of memberFields) {
  //     if (
  //       memberField.required &&
  //       (!form.hasOwnProperty(memberField.id) ||
  //         form[memberField.id].trim() === "")
  //     ) {
  //       errors[memberField.id] = "Toto pole je povinné.";
  //       isValid = false;
  //     }
  //   }
  //   setErrors(errors);
  //
  //   return isValid;
  // };

  const handleSubmit = () => {
    const form = store.getState().app.forms[formName];

    if (mode === FORM_MODE_CREATE) {
      create({ body: form });
    }
    if (mode === FORM_MODE_UPDATE) {
      update({ body: form, id: form.id });
    }
  };

  const disabled = updateResult.isLoading || createResult.isLoading;

  const handleAddOption = () => {
    dispatch({
      type: FORM_UPDATE,
      payload: {
        formName,
        name: "options",
        value: [...form.options].concat({ id: uuidv4(), title: "" }),
      },
    });
  };

  const handleChangeOption = (event) => {
    const { name, value } = event.target;

    let copyOptions = [...form.options];
    const index = copyOptions.findIndex((o) => o.id === name);
    if (index > -1) {
      const copyOption = { ...copyOptions[index] };
      copyOption.title = value;
      copyOptions[index] = copyOption;
    }

    //Pokud v seznamu, všechny možnosti mají nějakou hodnotu
    if (
      copyOptions.length ===
      copyOptions.filter((o) => {
        return o.title.trim() !== "";
      }).length
    ) {
      //Přidej do seznamu možností, novou prázdnou možnost
      copyOptions = copyOptions.concat({ id: uuidv4(), title: "" });
    }

    dispatch({
      type: FORM_UPDATE,
      payload: {
        formName,
        name: "options",
        value: copyOptions,
      },
    });
  };

  const handleRemoveOption = (name) => {
    const copyOptions = [...form.options];

    dispatch({
      type: FORM_UPDATE,
      payload: {
        formName,
        name: "options",
        value: copyOptions.filter((o) => {
          return o.id !== name;
        }),
      },
    });
  };

  const getFormFields = (mode, { memberGroups = [] }) => {
    const fields = {
      enabled: {
        formFieldType: formFieldTypeCheckbox,
        label: "Aktivní pole?",
      },
      title: {
        formFieldType: formFieldTypeSingleLineText,
        label: "Název",
        fullWidth: true,
        // error: validate && !validator.fieldValid("title"),
        // helperText:
        //   validate && !validator.fieldValid("title")
        //     ? validator.getErrorMessages()["title"]
        //     : null,
      },
      type: {
        formFieldType: formFieldTypeSingleSelect,
        options: Object.entries(fieldTypes).map((v) => {
          const [key, value] = v;
          return key;
          return { value: key, label: value.title };
        }),
        getOptionValue: (option) => {
          return option;
        },
        getOptionLabel: (option) => {
          return fieldTypes[option].title;
        },
        label: "Typ",
        fullWidth: true,
        // error: validate && !validator.fieldValid("type"),
        // helperText:
        //   validate && !validator.fieldValid("type")
        //     ? validator.getErrorMessages()["type"]
        //     : null,
        additionalRender: (
          <>
            {[formFieldTypeSingleSelect, formFieldTypeMultipleSelect].includes(
              form.type
            ) && (
              <FormFieldSelectAdditionalRender
                options={form.options || []}
                handleChangeOption={handleChangeOption}
                handleRemoveOption={handleRemoveOption}
                handleAddOption={handleAddOption}
              />
            )}
          </>
        ),
      },
      memberGroups: {
        formFieldType: formFieldTypeMultipleSelect,
        options: memberGroups.map((v) => {
          return v.id;
        }),
        getOptionValue: (option) => {
          return option;
        },
        getOptionLabel: (option) => {
          return memberGroups.find((memberGroup) => memberGroup.id === option)
            .title;
        },
        label: "Skupiny",
        fullWidth: true,
      },
      required: {
        formFieldType: formFieldTypeCheckbox,
        label: "Povinné?",
      },
      visibility: {
        formFieldType: formFieldTypeSingleSelect,
        options: Object.entries(fieldVisibilities).map((v) => {
          const [key, value] = v;
          return key;
          return { value: key, label: value.title };
        }),
        getOptionValue: (option) => {
          return option;
        },
        getOptionLabel: (option) => {
          return fieldVisibilities[option].title;
        },
        label: "Zobrazovat v adresáři",
        fullWidth: true,
        // error: validate && !validator.fieldValid("visibility"),
        // helperText:
        //   validate && !validator.fieldValid("visibility")
        //     ? validator.getErrorMessages()["visibility"]
        //     : null,
      },
      position: {
        formFieldType: formFieldTypeSingleLineText,
        label: "Pořadí",
        type: "number",
        fullWidth: true,
      },
    };

    return fields;
  };

  React.useEffect(() => {
    if (
      !!createResult &&
      !createResult.isLoading &&
      !createResult.isError &&
      createResult.isSuccess
    ) {
      dispatch(
        appSnackbarAdd({
          message: "Vlastnost byla vytvořena.",
          variant: "success",
        })
      );
      handleClose();
    }
    if (
      !!createResult &&
      !createResult.isLoading &&
      createResult.isError &&
      !createResult.isSuccess
    ) {
      dispatch(
        appSnackbarAdd({
          message: "Vlastnost nebyla vytvořena.",
          variant: "error",
        })
      );
    }
  }, [createResult]);

  React.useEffect(() => {
    if (
      !!updateResult &&
      !updateResult.isLoading &&
      !updateResult.isError &&
      updateResult.isSuccess
    ) {
      dispatch(
        appSnackbarAdd({
          message: "Vlastnost byla upravena.",
          variant: "success",
        })
      );
      handleClose();
    }
    if (
      !!updateResult &&
      !updateResult.isLoading &&
      updateResult.isError &&
      !updateResult.isSuccess
    ) {
      dispatch(
        appSnackbarAdd({
          message: "Vlastnost nebyla upravena.",
          variant: "error",
        })
      );
    }
  }, [updateResult]);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {mode === FORM_MODE_CREATE
            ? "Vytvořit vlastnost"
            : "Upravit vlastnost"}
          <IconButton
            sx={(theme) => {
              return {
                position: "absolute",
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              };
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {open && (
            <>
              {Object.entries(getFormFields(mode, { memberGroups })).map(
                (v) => {
                  const [name, field] = v;
                  return (
                    <FormField
                      key={name}
                      formName={formName}
                      name={name}
                      {...field}
                    />
                  );
                }
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disableElevation
            startIcon={mode === FORM_MODE_CREATE ? <AddCircle /> : <Save />}
            disabled={disabled}
          >
            {mode === FORM_MODE_CREATE
              ? "Vytvořit vlastnost"
              : "Upravit vlastnost"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MemberFieldDetail;
