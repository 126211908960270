import React from "react";
import { NavLink } from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import { Collapse, ListItem } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const SecuredLayoutMenuItem = (props) => {
  const { classes, item, paddingLeft } = props;

  const hasNested = item.hasOwnProperty("list");
  const [open, setOpen] = React.useState(false);

  if (item.hasOwnProperty("path")) {
    return (
      <ListItem
        style={{ paddingLeft: paddingLeft }}
        button
        key={item.title}
        component={NavLink}
        to={item.path}
        className={classes.listItem}
        exact
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText
          className={classes.listItemText}
          disableTypography
          primary={item.title}
        />
      </ListItem>
    );
  }

  return (
    <>
      <ListItem
        style={{ paddingLeft: paddingLeft }}
        button
        key={item.title}
        className={classes.listItem}
        onClick={() => {
          if (hasNested) {
            setOpen((prev) => {
              return !prev;
            });
          } else {
            if (item.hasOwnProperty("onClick")) {
              item.onClick();
            }
          }
        }}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText
          className={classes.listItemText}
          disableTypography
          primary={item.title}
        />
        {hasNested && <>{open ? <ExpandLess /> : <ExpandMore />}</>}
      </ListItem>
      {hasNested && (
        <Collapse in={open} unmountOnExit>
          <SecuredLayoutMenu
            classes={classes}
            list={item.list}
            disablePadding={true}
            paddingLeft={8 * 2}
          />
        </Collapse>
      )}
    </>
  );
};

const SecuredLayoutMenu = (props) => {
  const { classes, list, disablePadding = false, paddingLeft = 8 } = props;

  return (
    <List disablePadding={disablePadding}>
      {list.map((item, index) => {
        return (
          <SecuredLayoutMenuItem
            key={index}
            classes={classes}
            item={item}
            paddingLeft={paddingLeft}
          />
        );
      })}
    </List>
  );
};

export default SecuredLayoutMenu;
