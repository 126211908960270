import React from "react";
import {
  TYPE_MEMBER,
  useGetMemberGroupsAllQuery,
  useGetMembersAllQuery,
} from "../../api/api";
import { formSet } from "../../actions/appActions";
import { useDispatch } from "react-redux";
import { CreditCard, Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import DataTable from "../DataTable/DataTable";
import { USER_EMAIL_SET } from "../../constants/actionTypes";
import { Redirect } from "react-router-dom";
import { formatDate } from "../../utils/utils";

const MemberList = () => {
  const dispatch = useDispatch();

  const membersData = useGetMembersAllQuery();
  const memberGroupsData = useGetMemberGroupsAllQuery();

  const members = membersData.data?.["hydra:member"] || [];
  const memberGroups = memberGroupsData.data?.["hydra:member"] || [];

  const isLoading = membersData.isLoading || memberGroupsData.isLoading;

  const [redirect, setRedirect] = React.useState(null);

  const columns = [
    {
      field: "enabled",
      flex: 1,
      headerName: "Aktivní?",
      valueGetter: (params) => {
        return params.row.enabled === true ? "ANO" : "NE";
      },
    },
    {
      field: "email",
      flex: 4,
      headerName: "E-mail",
    },
    {
      field: "groups",
      flex: 4,
      headerName: "Skupiny",
      valueGetter: (params) => {
        const collection = memberGroups;
        const objectPropertyName = "memberGroups";
        const nestedObjectPropertyName = "title";

        const values = [];
        params.row[objectPropertyName].forEach((id) => {
          const nestedObject = collection.find((o) => {
            return o.id === id;
          });
          if (!!nestedObject) {
            values.push(nestedObject[nestedObjectPropertyName]);
          }
        });
        return values.join(", ");
      },
    },
    {
      field: "createdAt",
      flex: 1,
      headerName: "Vytvořeno",
      valueGetter: (params) => {
        return formatDate(params.row.createdAt);
      },
    },
    {
      field: "actions",
      flex: 1,
      headerName: "Akce",
      disableColumnMenu: true,
      type: "number",
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() => {
                dispatch(formSet(TYPE_MEMBER, params.row));
              }}
              color="primary"
              size="small"
            >
              <Edit fontSize="inherit" />
            </IconButton>
            <IconButton
              onClick={() => {
                dispatch({
                  type: USER_EMAIL_SET,
                  payload: {
                    userEmail: params.row.email,
                  },
                });
                setRedirect("/payment");
              }}
              color="primary"
              size="small"
            >
              <CreditCard fontSize="inherit" />
            </IconButton>
            {/*<MemberDeleteAction member={params.row} onSubmit={handleSubmit} />*/}
          </>
        );
      },
    },
  ];
  //

  if (redirect !== null) {
    return <Redirect to={redirect} />;
  }

  return (
    <>
      <DataTable
        columns={columns}
        rows={members}
        autoHeight={true}
        loading={isLoading}
      />
    </>
  );
};

export default MemberList;
