import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { readAuthToken } from "../auth/localStorage";

const DEFAULT_PARAMS = {
  page: 1,
  pageSize: 9999,
};

export const TYPE_MEMBER = "member";
export const TYPE_MEMBER_FIELD = "memberField";
export const TYPE_MEMBER_GROUP = "memberGroup";
export const TYPE_PAYMENT = "payment";
export const TYPE_PAYMENT_V0 = "paymentV0";
export const TYPE_PAYMENT_ORDER = "paymentOrder";
export const TYPE_PAYMENT_ORDER_TYPE = "paymentOrderType";
export const TYPE_PAYMENT_PAYMENT_ORDER = "paymentPaymentOrder";

export const TYPE_CPD_EVENT_CATEGORY = "cpdEventCategory";
export const TYPE_CPD_HOUR_TYPE = "cpdHourType";
export const TYPE_CPD_YEAR = "cpdYear";
export const TYPE_CPD_EVENT = "cpdEvent";
export const TYPE_CPD_EVENT_ATTENDEE = "cpdEventAttendee";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ENV_ENDPOINT_SERVER,
    prepareHeaders: (headers, { getState }) => {
      const token = readAuthToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: [
    TYPE_MEMBER,
    TYPE_MEMBER_GROUP,
    TYPE_MEMBER_FIELD,
    TYPE_PAYMENT_V0,
    TYPE_PAYMENT,
    TYPE_PAYMENT_ORDER,
    TYPE_PAYMENT_ORDER_TYPE,
    TYPE_PAYMENT_PAYMENT_ORDER,
    TYPE_CPD_EVENT_CATEGORY,
    TYPE_CPD_HOUR_TYPE,
    TYPE_CPD_YEAR,
    TYPE_CPD_EVENT,
    TYPE_CPD_EVENT_ATTENDEE,
  ],
  endpoints: (builder) => ({
    getProfile: builder.query({
      // note: an optional `queryFn` may be used in place of `query`
      query: (queryArg) => ({ url: `/users/me` }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response,
    }),
    createMember: builder.mutation({
      query: (queryArg) => ({
        url: `/members`,
        method: "POST",
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, id) => [TYPE_MEMBER],
    }),
    updateMember: builder.mutation({
      query: (queryArg) => ({
        url: `/members/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, id) => [TYPE_MEMBER],
    }),
    getMembersAll: builder.query({
      // note: an optional `queryFn` may be used in place of `query`
      query: (queryArg) => ({ url: `/members`, params: DEFAULT_PARAMS }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response,
      providesTags: (result, error, id) => [TYPE_MEMBER],
    }),
    createMemberField: builder.mutation({
      query: (queryArg) => ({
        url: `/member_fields`,
        method: "POST",
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, id) => [TYPE_MEMBER_FIELD],
    }),
    updateMemberField: builder.mutation({
      query: (queryArg) => ({
        url: `/member_fields/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, id) => [TYPE_MEMBER_FIELD],
    }),
    getMemberFieldsAll: builder.query({
      // note: an optional `queryFn` may be used in place of `query`
      query: (queryArg) => ({ url: `/member_fields`, params: DEFAULT_PARAMS }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response,
      providesTags: (result, error, id) => [TYPE_MEMBER_FIELD],
    }),
    createMemberGroup: builder.mutation({
      query: (queryArg) => ({
        url: `/member_groups`,
        method: "POST",
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, id) => [TYPE_MEMBER_GROUP],
    }),
    updateMemberGroup: builder.mutation({
      query: (queryArg) => ({
        url: `/member_groups/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, id) => [TYPE_MEMBER_GROUP],
    }),
    getMemberGroupsAll: builder.query({
      // note: an optional `queryFn` may be used in place of `query`
      query: (queryArg) => ({ url: `/member_groups`, params: DEFAULT_PARAMS }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response,
      providesTags: (result, error, id) => [TYPE_MEMBER_GROUP],
    }),
    createPaymentV0: builder.mutation({
      query: (queryArg) => ({
        url: `/payment_v0s`,
        method: "POST",
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, id) => [TYPE_PAYMENT_V0],
    }),
    updatePaymentV0: builder.mutation({
      query: (queryArg) => ({
        url: `/payment_v0s/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, id) => [TYPE_PAYMENT_V0],
    }),
    getPaymentV0sAll: builder.query({
      // note: an optional `queryFn` may be used in place of `query`
      query: (queryArg) => ({ url: `/payment_v0s`, params: DEFAULT_PARAMS }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response,
      providesTags: (result, error, id) => [TYPE_PAYMENT_V0],
    }),
    getCpdHourTypeAll: builder.query({
      // note: an optional `queryFn` may be used in place of `query`
      query: (queryArg) => ({ url: `/cpd_hour_types`, params: DEFAULT_PARAMS }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response,
      providesTags: (result, error, id) => [TYPE_CPD_HOUR_TYPE],
    }),
    createCpdEventCategory: builder.mutation({
      query: (queryArg) => ({
        url: `/cpd_event_categories`,
        method: "POST",
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, id) => [TYPE_CPD_EVENT_CATEGORY],
    }),
    updateCpdEventCategory: builder.mutation({
      query: (queryArg) => ({
        url: `/cpd_event_categories/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, id) => [TYPE_CPD_EVENT_CATEGORY],
    }),
    getCpdEventCategoryAll: builder.query({
      // note: an optional `queryFn` may be used in place of `query`
      query: (queryArg) => ({
        url: `/cpd_event_categories`,
        params: DEFAULT_PARAMS,
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response,
      providesTags: (result, error, id) => [TYPE_CPD_EVENT_CATEGORY],
    }),
    createCpdYear: builder.mutation({
      query: (queryArg) => ({
        url: `/cpd_years`,
        method: "POST",
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, id) => [TYPE_CPD_YEAR],
    }),
    updateCpdYear: builder.mutation({
      query: (queryArg) => ({
        url: `/cpd_years/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, id) => [TYPE_CPD_YEAR],
    }),
    getCpdYearAll: builder.query({
      // note: an optional `queryFn` may be used in place of `query`
      query: (queryArg) => ({ url: `/cpd_years`, params: DEFAULT_PARAMS }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response,
      providesTags: (result, error, id) => [TYPE_CPD_YEAR],
    }),
    createCpdEvent: builder.mutation({
      query: (queryArg) => ({
        url: `/cpd_events`,
        method: "POST",
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, id) => [TYPE_CPD_EVENT],
    }),
    updateCpdEvent: builder.mutation({
      query: (queryArg) => ({
        url: `/cpd_events/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, id) => [TYPE_CPD_EVENT],
    }),
    getCpdEventAll: builder.query({
      // note: an optional `queryFn` may be used in place of `query`
      query: (queryArg) => ({ url: `/cpd_events`, params: DEFAULT_PARAMS }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response,
      providesTags: (result, error, id) => [TYPE_CPD_EVENT],
    }),
    createCpdEventAttendee: builder.mutation({
      query: (queryArg) => ({
        url: `/cpd_event_attendees`,
        method: "POST",
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, id) => [TYPE_CPD_EVENT_ATTENDEE],
    }),
    updateCpdEventAttendee: builder.mutation({
      query: (queryArg) => ({
        url: `/cpd_event_attendees/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, id) => [TYPE_CPD_EVENT_ATTENDEE],
    }),
    getCpdEventAttendeeAll: builder.query({
      // note: an optional `queryFn` may be used in place of `query`
      query: (queryArg) => ({
        url: `/cpd_event_attendees`,
        params: DEFAULT_PARAMS,
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response,
      providesTags: (result, error, id) => [TYPE_CPD_EVENT_ATTENDEE],
    }),
    createPayment: builder.mutation({
      query: (queryArg) => ({
        url: `/payments`,
        method: "POST",
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, id) => [
        TYPE_PAYMENT,
        TYPE_PAYMENT_ORDER,
        TYPE_PAYMENT_ORDER_TYPE,
        TYPE_PAYMENT_PAYMENT_ORDER,
      ],
    }),
    updatePayment: builder.mutation({
      query: (queryArg) => ({
        url: `/payments/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, id) => [
        TYPE_PAYMENT,
        TYPE_PAYMENT_ORDER,
        TYPE_PAYMENT_ORDER_TYPE,
        TYPE_PAYMENT_PAYMENT_ORDER,
      ],
    }),
    getPaymentsAll: builder.query({
      // note: an optional `queryFn` may be used in place of `query`
      query: (queryArg) => ({ url: `/payments`, params: DEFAULT_PARAMS }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response,
      providesTags: (result, error, id) => [
        TYPE_PAYMENT,
        TYPE_PAYMENT_ORDER,
        TYPE_PAYMENT_ORDER_TYPE,
        TYPE_PAYMENT_PAYMENT_ORDER,
      ],
    }),
    createPaymentOrder: builder.mutation({
      query: (queryArg) => ({
        url: `/payment_orders`,
        method: "POST",
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, id) => [
        TYPE_PAYMENT,
        TYPE_PAYMENT_ORDER,
        TYPE_PAYMENT_ORDER_TYPE,
        TYPE_PAYMENT_PAYMENT_ORDER,
      ],
    }),
    updatePaymentOrder: builder.mutation({
      query: (queryArg) => ({
        url: `/payment_orders/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, id) => [
        TYPE_PAYMENT,
        TYPE_PAYMENT_ORDER,
        TYPE_PAYMENT_ORDER_TYPE,
        TYPE_PAYMENT_PAYMENT_ORDER,
      ],
    }),
    getPaymentOrdersAll: builder.query({
      // note: an optional `queryFn` may be used in place of `query`
      query: (queryArg) => ({ url: `/payment_orders`, params: DEFAULT_PARAMS }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response,
      providesTags: (result, error, id) => [
        TYPE_PAYMENT,
        TYPE_PAYMENT_ORDER,
        TYPE_PAYMENT_ORDER_TYPE,
        TYPE_PAYMENT_PAYMENT_ORDER,
      ],
    }),
    createPaymentOrderType: builder.mutation({
      query: (queryArg) => ({
        url: `/payment_order_types`,
        method: "POST",
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, id) => [
        TYPE_PAYMENT,
        TYPE_PAYMENT_ORDER,
        TYPE_PAYMENT_ORDER_TYPE,
        TYPE_PAYMENT_PAYMENT_ORDER,
      ],
    }),
    updatePaymentOrderType: builder.mutation({
      query: (queryArg) => ({
        url: `/payment_order_types/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, id) => [
        TYPE_PAYMENT,
        TYPE_PAYMENT_ORDER,
        TYPE_PAYMENT_ORDER_TYPE,
        TYPE_PAYMENT_PAYMENT_ORDER,
      ],
    }),
    getPaymentOrderTypesAll: builder.query({
      // note: an optional `queryFn` may be used in place of `query`
      query: (queryArg) => ({
        url: `/payment_order_types`,
        params: DEFAULT_PARAMS,
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response,
      providesTags: (result, error, id) => [
        TYPE_PAYMENT,
        TYPE_PAYMENT_ORDER,
        TYPE_PAYMENT_ORDER_TYPE,
        TYPE_PAYMENT_PAYMENT_ORDER,
      ],
    }),
    createPaymentPaymentOrder: builder.mutation({
      query: (queryArg) => ({
        url: `/payment_payment_orders`,
        method: "POST",
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, id) => [
        TYPE_PAYMENT,
        TYPE_PAYMENT_ORDER,
        TYPE_PAYMENT_ORDER_TYPE,
        TYPE_PAYMENT_PAYMENT_ORDER,
      ],
    }),
    updatePaymentPaymentOrder: builder.mutation({
      query: (queryArg) => ({
        url: `/payment_payment_orders/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
      invalidatesTags: (result, error, id) => [
        TYPE_PAYMENT,
        TYPE_PAYMENT_ORDER,
        TYPE_PAYMENT_ORDER_TYPE,
        TYPE_PAYMENT_PAYMENT_ORDER,
      ],
    }),
    getPaymentPaymentOrdersAll: builder.query({
      // note: an optional `queryFn` may be used in place of `query`
      query: (queryArg) => ({
        url: `/payment_payment_orders`,
        params: DEFAULT_PARAMS,
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response,
      providesTags: (result, error, id) => [
        TYPE_PAYMENT,
        TYPE_PAYMENT_ORDER,
        TYPE_PAYMENT_ORDER_TYPE,
        TYPE_PAYMENT_PAYMENT_ORDER,
      ],
    }),
  }),
});

export const {
  useGetProfileQuery,
  useCreateMemberMutation,
  useUpdateMemberMutation,
  useGetMembersAllQuery,
  useCreateMemberFieldMutation,
  useUpdateMemberFieldMutation,
  useGetMemberFieldsAllQuery,
  useCreateMemberGroupMutation,
  useUpdateMemberGroupMutation,
  useGetMemberGroupsAllQuery,
  useCreatePaymentV0Mutation,
  useUpdatePaymentV0Mutation,
  useGetPaymentV0sAllQuery,
  useCreatePaymentMutation,
  useUpdatePaymentMutation,
  useGetPaymentsAllQuery,
  useCreatePaymentOrderMutation,
  useUpdatePaymentOrderMutation,
  useGetPaymentOrdersAllQuery,
  useCreatePaymentOrderTypeMutation,
  useUpdatePaymentOrderTypeMutation,
  useGetPaymentOrderTypesAllQuery,
  useCreatePaymentPaymentOrderMutation,
  useUpdatePaymentPaymentOrderMutation,
  useGetPaymentPaymentOrdersAllQuery,
  useGetCpdHourTypeAllQuery,
  useCreateCpdEventCategoryMutation,
  useUpdateCpdEventCategoryMutation,
  useGetCpdEventCategoryAllQuery,
  useCreateCpdYearMutation,
  useUpdateCpdYearMutation,
  useGetCpdYearAllQuery,
  useCreateCpdEventMutation,
  useUpdateCpdEventMutation,
  useGetCpdEventAllQuery,
  useCreateCpdEventAttendeeMutation,
  useUpdateCpdEventAttendeeMutation,
  useGetCpdEventAttendeeAllQuery,
} = api;
