import React from "react";
import {
  formFieldTypeNumber,
  formFieldTypeSingleSelect,
} from "../../constants/enum";
import { FORM_MODE_CREATE, FORM_MODE_UPDATE } from "../../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFormByFormName,
  selectFormByFormNameIsOpen,
  selectFormByFormNameMode,
} from "../../selectors/selectors";
import {
  TYPE_CPD_EVENT_ATTENDEE,
  useCreateCpdEventAttendeeMutation,
  useGetCpdEventAllQuery,
  useGetCpdEventCategoryAllQuery,
  useGetCpdHourTypeAllQuery,
  useGetCpdYearAllQuery,
  useGetMembersAllQuery,
  useUpdateCpdEventAttendeeMutation,
} from "../../api/api";
import { appSnackbarAdd, formSet } from "../../actions/appActions";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { AddCircle, Save } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import FormField from "../Form/FormField";
import { store } from "../../store";

const formName = TYPE_CPD_EVENT_ATTENDEE;

const EventDetail = () => {
  const form = useSelector((state) =>
    selectFormByFormName(state, { formName })
  );

  const eventsData = useGetCpdEventAllQuery();
  const categoriesData = useGetCpdEventCategoryAllQuery();
  const yearsData = useGetCpdYearAllQuery();
  const hourTypesData = useGetCpdHourTypeAllQuery();

  const events = eventsData.data?.["hydra:member"] || [];
  const categories = categoriesData.data?.["hydra:member"] || [];
  const years = yearsData.data?.["hydra:member"] || [];
  const hourTypes = hourTypesData.data?.["hydra:member"] || [];

  if (!form || !form.eventId) {
    return <></>;
  }

  const index = events.findIndex((e) => e.id === form.eventId);
  if (index === -1) {
    return <></>;
  }

  const event = events[index];

  const index2 = years.findIndex((y) => y.id === event.yearId);
  if (index2 === -1) {
    return <></>;
  }

  const year = years[index2];

  return (
    <Box sx={{ mb: 2 }}>
      <Box>
        <strong>Kód:</strong> {event.code}
      </Box>
      <Box>
        <strong>Název:</strong> {event.title}
      </Box>
      <Box>
        <strong>Rok:</strong> {year.title}
      </Box>
      {hourTypes
        .filter((ht) => ht.useInSubsidization)
        .map((ht) => {
          const index3 = event.hours.findIndex((h) => h["type_id"] === ht.id);
          if (index3 > -1) {
            return (
              <Box key={ht.id}>
                <strong>Maximální dotace {ht.title}:</strong>{" "}
                {event.hours[index3].value}
              </Box>
            );
          }
          return <></>;
        })}
    </Box>
  );
};

const getFormFields = (mode, { members, events, hourTypes }) => {
  const fields = {};
  fields.memberId = {
    formFieldType: formFieldTypeSingleSelect,
    label: "Člen",
    fullWidth: true,
    options: members.map((v) => {
      return v.id;
    }),
    getOptionValue: (option) => {
      return option;
    },
    getOptionLabel: (option) => {
      const index = members.findIndex((v) => v["id"] === option);
      if (index > -1) {
        return members[index].email;
      }
      return option;
    },
  };
  fields.eventId = {
    formFieldType: formFieldTypeSingleSelect,
    label: "Akce",
    fullWidth: true,
    options: events.map((v) => {
      return v.id;
    }),
    getOptionValue: (option) => {
      return option;
    },
    getOptionLabel: (option) => {
      const index = events.findIndex((v) => v["id"] === option);
      if (index > -1) {
        return events[index].title;
      }
      return option;
    },
    additionalRender: <EventDetail />,
  };
  hourTypes
    .filter((ht) => ht.useInAllocation)
    .forEach((ht) => {
      fields["hour_" + ht.id] = {
        formFieldType: formFieldTypeNumber,
        label: "Přidělené hodiny: " + ht.title,
        fullWidth: true,
      };
    });

  return fields;
};

const CpdEventAttendeeDetail = () => {
  const dispatch = useDispatch();

  const open = useSelector((state) =>
    selectFormByFormNameIsOpen(state, { formName })
  );
  const mode = useSelector((state) =>
    selectFormByFormNameMode(state, { formName })
  );

  const membersData = useGetMembersAllQuery();
  const eventsData = useGetCpdEventAllQuery();
  const categoriesData = useGetCpdEventCategoryAllQuery();
  const yearsData = useGetCpdYearAllQuery();
  const hourTypesData = useGetCpdHourTypeAllQuery();

  const members = membersData.data?.["hydra:member"] || [];
  const events = eventsData.data?.["hydra:member"] || [];
  const categories = categoriesData.data?.["hydra:member"] || [];
  const years = yearsData.data?.["hydra:member"] || [];
  const hourTypes = hourTypesData.data?.["hydra:member"] || [];

  const [create, createResult] = useCreateCpdEventAttendeeMutation();
  const [update, updateResult] = useUpdateCpdEventAttendeeMutation();

  const handleClose = () => {
    dispatch(formSet(formName, null));
  };

  const [errors, setErrors] = React.useState({});

  const handleSubmit = () => {
    const form = { ...store.getState().app.forms[formName] };

    const body = { ...form };

    body.hours = Object.keys(form)
      .filter((key) => {
        return key.startsWith("hour_");
      })
      .map((key) => {
        const ids = key.split("_");
        return {
          type_id: ids[1],
          value: form[key] * 1,
        };
      });

    if (mode === FORM_MODE_CREATE) {
      create({ body: body });
    }
    if (mode === FORM_MODE_UPDATE) {
      body.id = form.id;
      update({ body: body, id: body.id });
    }
  };

  const disabled = updateResult.isLoading || createResult.isLoading;

  React.useEffect(() => {
    if (
      !!createResult &&
      !createResult.isLoading &&
      !createResult.isError &&
      createResult.isSuccess
    ) {
      dispatch(
        appSnackbarAdd({
          message: "Účast byla vytvořena.",
          variant: "success",
        })
      );
      handleClose();
    }
    if (
      !!createResult &&
      !createResult.isLoading &&
      createResult.isError &&
      !createResult.isSuccess
    ) {
      dispatch(
        appSnackbarAdd({
          message: "Účast nebyla vytvořena.",
          variant: "error",
        })
      );
    }
  }, [createResult]);

  React.useEffect(() => {
    if (
      !!updateResult &&
      !updateResult.isLoading &&
      !updateResult.isError &&
      updateResult.isSuccess
    ) {
      dispatch(
        appSnackbarAdd({
          message: "Účast byla upravena.",
          variant: "success",
        })
      );
      handleClose();
    }
    if (
      !!updateResult &&
      !updateResult.isLoading &&
      updateResult.isError &&
      !updateResult.isSuccess
    ) {
      dispatch(
        appSnackbarAdd({
          message: "Účast nebyla upravena.",
          variant: "error",
        })
      );
    }
  }, [updateResult]);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {mode === FORM_MODE_CREATE ? "Vytvořit účast" : "Upravit účast"}
          <IconButton
            sx={(theme) => {
              return {
                position: "absolute",
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              };
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {open && (
            <>
              {Object.entries(
                getFormFields(mode, { hourTypes, events, members })
              ).map((v) => {
                const [name, field] = v;
                return (
                  <FormField
                    key={name}
                    formName={formName}
                    name={name}
                    {...field}
                  />
                );
              })}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disableElevation
            startIcon={mode === FORM_MODE_CREATE ? <AddCircle /> : <Save />}
            disabled={disabled}
          >
            {mode === FORM_MODE_CREATE ? "Vytvořit účast" : "Upravit účast"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CpdEventAttendeeDetail;
