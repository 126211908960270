import React from "react";
import { TYPE_MEMBER_GROUP, useGetMemberGroupsAllQuery } from "../../api/api";
import { formSet } from "../../actions/appActions";
import { useDispatch } from "react-redux";
import { Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import DataTable from "../DataTable/DataTable";

const MemberList = () => {
  const dispatch = useDispatch();

  const memberGroupsData = useGetMemberGroupsAllQuery();

  const memberGroups = memberGroupsData.data?.["hydra:member"] || [];

  const isLoading = memberGroupsData.isLoading;

  const columns = [
    {
      field: "title",
      flex: 2,
      headerName: "Název",
    },
    {
      field: "code",
      flex: 2,
      headerName: "Kód",
    },
    {
      field: "description",
      flex: 6,
      headerName: "Popis",
    },
    {
      field: "actions",
      flex: 1,
      headerName: "Akce",
      disableColumnMenu: true,
      type: "number",
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() => {
                dispatch(formSet(TYPE_MEMBER_GROUP, params.row));
              }}
              color="primary"
              size="small"
            >
              <Edit fontSize="inherit" />
            </IconButton>
            {/*<MemberDeleteAction member={params.row} onSubmit={handleSubmit} />*/}
          </>
        );
      },
    },
  ];
  //
  return (
    <>
      <DataTable
        columns={columns}
        rows={memberGroups}
        autoHeight={true}
        loading={isLoading}
      />
    </>
  );
};

export default MemberList;
