import React from "react";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import { makeStyles } from "@mui/styles";
import { authRedirectToHomePage, authTryLogin } from "../../auth/auth";
import { storeAuthToken } from "../../auth/localStorage";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8),
  },
  logo: {
    width: 200,
    height: "auto",
    marginBottom: theme.spacing(2),
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    marginBottom: theme.spacing(2),
  },
}));

const initialForm = {
  email: "",
  password: "",
};

const initialFormError = null;

const Login = (props) => {
  const classes = useStyles();

  const [form, setForm] = React.useState(initialForm);
  const [formError, setFormError] = React.useState(initialFormError);
  const [loading, setLoading] = React.useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setFormError(initialFormError);
    setLoading(true);

    await authTryLogin(
      form,
      (response) => {
        storeAuthToken(response.data.token); //store auth token
        authRedirectToHomePage(); //redirect to homepage
      },
      (error) => {
        setFormError("Přihlašovací údaje jsou neplatné.");
        setLoading(false);
      }
    );
  };

  return (
    <div className={classes.paper}>
      <img
        alt="Actuaria"
        className={classes.logo}
        src="/images/actuaria-logo-blue.svg"
      />
      <form className={classes.form}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="E-mail"
          name="email"
          autoFocus
          value={form.email}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Heslo"
          type="password"
          value={form.password}
          onChange={handleChange}
        />
        {/*<FormControlLabel*/}
        {/*  control={<Checkbox value="remember" color="primary" />}*/}
        {/*  label="Zapamatovat si přihlášení"*/}
        {/*/>*/}
        <LoadingButton
          loading={loading}
          disabled={loading}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disableElevation
          onClick={handleSubmit}
        >
          Přihlásit
        </LoadingButton>
        {formError && (
          <Alert className={classes.errorMessage} severity="error">
            {formError}
          </Alert>
        )}

        <Box textAlign={"right"}>
          <Link
            href="https://www.actuaria.cz/zapomenute-heslo/"
            target={"_blank"}
            variant="body2"
          >
            Zapomenuté heslo
          </Link>
        </Box>
      </form>
    </div>
  );
};

export default Login;
