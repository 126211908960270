import React from "react";
import { useDispatch } from "react-redux";
import { formSet } from "../../actions/appActions";
import { initialPaymentV0 } from "../../constants/factories";
import { AddCircle } from "@mui/icons-material";
import { Button } from "@mui/material";
import { TYPE_PAYMENT_V0 } from "../../api/api";
import PaymentV0List from "./PaymentV0List";

const PaymentV0 = () => {
  const dispatch = useDispatch();

  const handleCreate = () => {
    dispatch(formSet(TYPE_PAYMENT_V0, { ...initialPaymentV0 }));
  };

  return (
    <>
      <Button
        sx={{ mt: 2, mb: 2 }}
        onClick={handleCreate}
        color="primary"
        variant="contained"
        disableElevation
        startIcon={<AddCircle />}
      >
        Vytvořit platbu
      </Button>
      <PaymentV0List />
    </>
  );
};

export default PaymentV0;
