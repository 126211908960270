import React from "react";
import { useDispatch } from "react-redux";
import { formSet } from "../../actions/appActions";
import { initialCpdEventAttendee } from "../../constants/factories";
import { AddCircle } from "@mui/icons-material";
import { Button } from "@mui/material";
import { TYPE_CPD_EVENT_ATTENDEE } from "../../api/api";
import CpdEventAttendeeList from "./CpdEventAttendeeList";

const CpdEventAttendee = () => {
  const dispatch = useDispatch();

  const handleCreate = () => {
    dispatch(formSet(TYPE_CPD_EVENT_ATTENDEE, { ...initialCpdEventAttendee }));
  };

  return (
    <>
      <Button
        sx={{ mt: 2, mb: 2 }}
        onClick={handleCreate}
        color="primary"
        variant="contained"
        disableElevation
        startIcon={<AddCircle />}
      >
        Vytvořit účast
      </Button>
      <CpdEventAttendeeList />
    </>
  );
};

export default CpdEventAttendee;
