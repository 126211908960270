import React from "react";
import { fieldTypes } from "../../constants/enum";
import { useDispatch, useSelector } from "react-redux";
import { FORM_UPDATE } from "../../constants/actionTypes";
import { selectFormValueByFormNameAndFieldName } from "../../selectors/selectors";

const FormField = (props) => {
  const {
    formName,
    formFieldType,
    additionalRender,
    name,
    inValues = false,
    ...rest
  } = props;

  const fieldType = fieldTypes[formFieldType];

  const dispatch = useDispatch();
  const value = useSelector((state) =>
    selectFormValueByFormNameAndFieldName(state, { formName, name })
  );

  const handleChange = (event) => {
    dispatch({
      type: FORM_UPDATE,
      payload: {
        formName,
        name,
        value:
          formFieldType === "checkbox"
            ? event.target.checked
            : event.target.value,
        inValues: inValues,
      },
    });
  };

  return (
    <>
      {React.createElement(fieldType.component, {
        ...rest,
        name,
        value,
        onChange: handleChange,
      })}
      {!!additionalRender && additionalRender}
    </>
  );
};

export default FormField;
