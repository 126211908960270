import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import SecuredLayout from "../layouts/SecuredLayout/SecuredLayout";
import LoginView from "../views/LoginView";
import UnsecuredLayout from "../layouts/UnsecuredLayout/UnsecuredLayout";
import MemberField from "../components/MemberField/MemberField";
import MemberGroup from "../components/MemberGroup/MemberGroup";
import Member from "../components/Member/Member";
import PaymentOrderType from "../components/PaymentOrderType/PaymentOrderType";
import PaymentV0 from "../components/PaymentV0/PaymentV0";
import CpdEventCategory from "../components/CpdEventCategory/CpdEventCategory";
import CpdYear from "../components/CpdYear/CpdYear";
import CpdEvent from "../components/CpdEvent/CpdEvent";
import CpdEventAttendee from "../components/CpdEventAttendee/CpdEventAttendee";

export const routes = [
  {
    path: "/member/field",
    view: MemberField,
    layout: SecuredLayout,
    title: "Vlastnosti členů",
  },
  {
    path: "/member/group",
    view: MemberGroup,
    layout: SecuredLayout,
    title: "Skupiny členství",
  },
  { path: "/member", view: Member, layout: SecuredLayout, title: "Členové" },
  // {
  //   path: "/payment_order",
  //   view: PaymentOrder,
  //   layout: SecuredLayout,
  //   title: "Předpisy plateb",
  // },
  {
    path: "/payment",
    view: PaymentV0,
    layout: SecuredLayout,
    title: "Platby",
  },
  // {
  //   path: "/payment_order_payment_pairing",
  //   view: PaymentPaymentOrder,
  //   layout: SecuredLayout,
  //   title: "Párování plateb",
  // },
  {
    path: "/payment_order_type",
    view: PaymentOrderType,
    layout: SecuredLayout,
    title: "Typy předpisů plateb",
  },
  {
    path: "/cpd_event_category",
    view: CpdEventCategory,
    layout: SecuredLayout,
    title: "Kategorie akcí",
  },
  {
    path: "/cpd_year",
    view: CpdYear,
    layout: SecuredLayout,
    title: "Roční minimální požadavky",
  },
  {
    path: "/cpd_event",
    view: CpdEvent,
    layout: SecuredLayout,
    title: "Akce",
  },
  {
    path: "/cpd_event_attendee",
    view: CpdEventAttendee,
    layout: SecuredLayout,
    title: "Účasti",
  },
  { path: "/login", view: LoginView, layout: UnsecuredLayout },
];

export const redirects = [{ from: "/", to: "/login" }];

const RouteWithLayout = ({ layout, view, title, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        React.createElement(
          layout,
          { ...props, title: title },
          React.createElement(view, props)
        )
      }
    />
  );
};

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        {routes.map((route) => {
          return (
            <RouteWithLayout
              title={route.title}
              key={route.path}
              layout={route.layout}
              path={route.path}
              exact
              view={route.view}
            />
          );
        })}
        {redirects.map((redirect) => {
          return (
            <Redirect
              key={redirect.from + redirect.to}
              from={redirect.from}
              to={redirect.to}
            />
          );
        })}
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
