import React from "react";
import {
  TYPE_MEMBER_FIELD,
  useGetMemberFieldsAllQuery,
  useGetMemberGroupsAllQuery,
} from "../../api/api";
import { formSet } from "../../actions/appActions";
import { useDispatch } from "react-redux";
import { Edit } from "@mui/icons-material";
import { Chip, IconButton } from "@mui/material";

import { fieldTypes, fieldVisibilities } from "../../constants/enum";

import DataTable from "../DataTable/DataTable";

const MemberFieldList = () => {
  const dispatch = useDispatch();

  const memberFieldsData = useGetMemberFieldsAllQuery();
  const memberGroupsData = useGetMemberGroupsAllQuery();

  const memberFields = memberFieldsData.data?.["hydra:member"] || [];
  const memberGroups = memberGroupsData.data?.["hydra:member"] || [];

  const isLoading = memberFields.isLoading || memberGroupsData.isLoading;

  const columns = [
    {
      field: "enabled",
      flex: 1,
      headerName: "Aktivní?",
      renderCell: (params) => {
        return <>{params.row.enabled ? "ANO" : "NE"}</>;
      },
    },
    {
      field: "position",
      flex: 1,
      headerName: "Pořadí",
    },
    {
      field: "title",
      flex: 4,
      headerName: "Název",
      width: 400,
    },
    {
      field: "type",
      flex: 2,
      headerName: "Typ",
      renderCell: (params) => {
        const type = fieldTypes[params.row.type];
        if (type) {
          return (
            <Chip icon={React.createElement(type.icon)} label={type.title} />
          );
        }
        return null;
      },
    },
    {
      field: "groups",
      flex: 3,
      headerName: "Skupiny",
      valueGetter: (params) => {
        const collection = memberGroups;
        const objectPropertyName = "memberGroups";
        const nestedObjectPropertyName = "title";

        const values = [];
        params.row[objectPropertyName].forEach((id) => {
          const nestedObject = collection.find((o) => {
            return o.id === id;
          });
          if (!!nestedObject) {
            values.push(nestedObject[nestedObjectPropertyName]);
          }
        });
        return values.join(", ");
      },
    },
    {
      field: "required",
      flex: 1,
      headerName: "Povinné?",
      renderCell: (params) => {
        return <>{params.row.required * 1 === 1 ? "ANO" : "NE"}</>;
      },
    },
    {
      field: "fieldVisibility",
      flex: 2,
      headerName: "Zobrazovat v adresáři?",
      renderCell: (params) => {
        return <>{fieldVisibilities[params.row.visibility]?.title}</>;
      },
    },
    {
      field: "actions",
      flex: 1,
      headerName: "Akce",
      disableColumnMenu: true,
      type: "number",
      filterable: false,
      sortable: false,
      align: "right",
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() => {
                dispatch(formSet(TYPE_MEMBER_FIELD, params.row));
              }}
              color="primary"
              size="small"
            >
              <Edit fontSize="inherit" />
            </IconButton>
            {/*<MemberDeleteAction member={params.row} onSubmit={handleSubmit} />*/}
          </>
        );
      },
    },
  ];

  return (
    <>
      <DataTable
        columns={columns}
        rows={memberFields}
        autoHeight={true}
        loading={isLoading}
      />
    </>
  );
};

export default MemberFieldList;
