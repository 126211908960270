import { DateTimePicker } from "@mui/lab";
import { textFieldMargin, textFieldVariant } from "../../../theme/theme";
import React from "react";
import { TextField } from "@mui/material";

const dateIsValid = (date) => {
  return !Number.isNaN(new Date(date).getTime());
};

const FormFieldDateTime = (props) => {
  const { name, onChange, value, ...rest } = props;

  return (
    <DateTimePicker
      autoOk
      clearable
      ampm={false}
      format={"D. M. yyyy HH:mm"}
      minutesStep={5}
      cancelLabel={"Storno"}
      clearLabel={"Smazat"}
      okLabel={"Ok"}
      inputVariant={textFieldVariant}
      value={value}
      onChange={(value) => {
        if (!value) {
          onChange({
            target: {
              type: "date",
              name,
              value: null,
            },
          });
        } else {
          if (dateIsValid(value)) {
            onChange({
              target: {
                type: "date",
                name,
                value: new Date(value).toISOString(),
              },
            });
          }
        }

        // const event = {
        //   target: {
        //     type: "date",
        //     name,
        //     value: !!value ? new Date(value).toISOString() : null,
        //   },
        // };
        // onChange(event);
      }}
      renderInput={(params) => (
        <TextField
          variant={textFieldVariant}
          margin={textFieldMargin}
          {...params}
        />
      )}
      {...rest}
    />
  );
};

export default FormFieldDateTime;
