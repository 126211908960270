import React from "react";
import { formFieldTypeSingleLineText } from "../../constants/enum";
import { FORM_MODE_CREATE, FORM_MODE_UPDATE } from "../../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFormByFormNameIsOpen,
  selectFormByFormNameMode,
} from "../../selectors/selectors";
import {
  TYPE_MEMBER_GROUP,
  useCreateMemberGroupMutation,
  useUpdateMemberGroupMutation,
} from "../../api/api";
import { appSnackbarAdd, formSet } from "../../actions/appActions";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { AddCircle, Save } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import FormField from "../Form/FormField";
import { store } from "../../store";

const formName = TYPE_MEMBER_GROUP;

const getFormFields = (mode) => {
  const fields = {};
  fields.title = {
    formFieldType: formFieldTypeSingleLineText,
    label: "Název",
    fullWidth: true,
    // error: validate && !validator.fieldValid("title"),
    //     helperText:
    // validate && !validator.fieldValid("title")
    //     ? validator.getErrorMessages()["title"]
    //     : null,
  };
  fields.code = {
    formFieldType: formFieldTypeSingleLineText,
    label: "Kód",
    fullWidth: true,
    // error: validate && !validator.fieldValid("title"),
    //     helperText:
    // validate && !validator.fieldValid("title")
    //     ? validator.getErrorMessages()["title"]
    //     : null,
  };
  fields.description = {
    formFieldType: formFieldTypeSingleLineText,
    label: "Popis",
    fullWidth: true,
    //     error: validate && !validator.fieldValid("description"),
    //     helperText:
    // validate && !validator.fieldValid("description")
    //     ? validator.getErrorMessages()["description"]
    //     : null,
  };

  return fields;
};

const MemberGroupDetail = () => {
  const dispatch = useDispatch();

  const open = useSelector((state) =>
    selectFormByFormNameIsOpen(state, { formName })
  );
  const mode = useSelector((state) =>
    selectFormByFormNameMode(state, { formName })
  );

  const [create, createResult] = useCreateMemberGroupMutation();
  const [update, updateResult] = useUpdateMemberGroupMutation();

  const handleClose = () => {
    dispatch(formSet(formName, null));
  };

  const [errors, setErrors] = React.useState({});

  // const validateForm = () => {
  //   const errors = {};
  //   let isValid = true;
  //   for (let memberField of memberFields) {
  //     if (
  //       memberField.required &&
  //       (!form.hasOwnProperty(memberField.id) ||
  //         form[memberField.id].trim() === "")
  //     ) {
  //       errors[memberField.id] = "Toto pole je povinné.";
  //       isValid = false;
  //     }
  //   }
  //   setErrors(errors);
  //
  //   return isValid;
  // };

  const handleSubmit = () => {
    const form = store.getState().app.forms[formName];

    if (mode === FORM_MODE_CREATE) {
      create({ body: form });
    }
    if (mode === FORM_MODE_UPDATE) {
      update({ body: form, id: form.id });
    }
  };

  const disabled = updateResult.isLoading || createResult.isLoading;

  React.useEffect(() => {
    if (
      !!createResult &&
      !createResult.isLoading &&
      !createResult.isError &&
      createResult.isSuccess
    ) {
      dispatch(
        appSnackbarAdd({
          message: "Skupina členství byla vytvořena.",
          variant: "success",
        })
      );
      handleClose();
    }
    if (
      !!createResult &&
      !createResult.isLoading &&
      createResult.isError &&
      !createResult.isSuccess
    ) {
      dispatch(
        appSnackbarAdd({
          message: "Skupina členství nebyla vytvořena.",
          variant: "error",
        })
      );
    }
  }, [createResult]);

  React.useEffect(() => {
    if (
      !!updateResult &&
      !updateResult.isLoading &&
      !updateResult.isError &&
      updateResult.isSuccess
    ) {
      dispatch(
        appSnackbarAdd({
          message: "Skupina členství byla upravena.",
          variant: "success",
        })
      );
      handleClose();
    }
    if (
      !!updateResult &&
      !updateResult.isLoading &&
      updateResult.isError &&
      !updateResult.isSuccess
    ) {
      dispatch(
        appSnackbarAdd({
          message: "Skupina členství nebyla upravena.",
          variant: "error",
        })
      );
    }
  }, [updateResult]);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {mode === FORM_MODE_CREATE
            ? "Vytvořit skupinu členství"
            : "Upravit skupinu členství"}
          <IconButton
            sx={(theme) => {
              return {
                position: "absolute",
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              };
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {open && (
            <>
              {Object.entries(getFormFields(mode)).map((v) => {
                const [name, field] = v;
                return (
                  <FormField
                    key={name}
                    formName={formName}
                    name={name}
                    {...field}
                  />
                );
              })}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disableElevation
            startIcon={mode === FORM_MODE_CREATE ? <AddCircle /> : <Save />}
            disabled={disabled}
          >
            {mode === FORM_MODE_CREATE
              ? "Vytvořit skupinu členství"
              : "Upravit skupinu členství"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MemberGroupDetail;
