import React from "react";
import { useDispatch } from "react-redux";
import { formSet } from "../../actions/appActions";
import { initialPaymentOrderType } from "../../constants/factories";
import { AddCircle } from "@mui/icons-material";
import { Button } from "@mui/material";
import { TYPE_PAYMENT_ORDER_TYPE } from "../../api/api";
import PaymentOrderTypeList from "./PaymentOrderTypeList";

const PaymentOrderType = () => {
  const dispatch = useDispatch();

  const handleCreate = () => {
    dispatch(formSet(TYPE_PAYMENT_ORDER_TYPE, { ...initialPaymentOrderType }));
  };

  return (
    <>
      <Button
        sx={{ mt: 2, mb: 2 }}
        onClick={handleCreate}
        color="primary"
        variant="contained"
        disableElevation
        startIcon={<AddCircle />}
      >
        Vytvořit typ předpisu platby
      </Button>
      <PaymentOrderTypeList />
    </>
  );
};

export default PaymentOrderType;
