import TextFormatIcon from "@mui/icons-material/TextFormat";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import TodayIcon from "@mui/icons-material/Today";
import LinkIcon from "@mui/icons-material/Link";
import EmailIcon from "@mui/icons-material/Email";
import Filter1Icon from "@mui/icons-material/Filter1";
import ListIcon from "@mui/icons-material/List";
import ListAltIcon from "@mui/icons-material/ListAlt";
import FormFieldLongText from "../components/Form/FormField/FormFieldLongText";
import FormFieldSingleLineText from "../components/Form/FormField/FormFieldSingleLineText";
import FormFieldCheckbox from "../components/Form/FormField/FormFieldCheckbox";
import FormFieldSingleSelect from "../components/Form/FormField/FormFieldSingleSelect";
import FormFieldMultipleSelect from "../components/Form/FormField/FormFieldMultipleSelect";
import FormFieldUrl from "../components/Form/FormField/FormFieldUrl";
import FormFieldEmail from "../components/Form/FormField/FormFieldEmail";
import FormFieldNumber from "../components/Form/FormField/FormFieldNumber";
import FormFieldDate from "../components/Form/FormField/FormFieldDate";
import FormFieldDateTime from "../components/Form/FormField/FormFieldDateTime";

export const formFieldTypeSingleLineText = "singleLineText";
export const formFieldTypeLongText = "longText";
export const formFieldTypeCheckbox = "checkbox";
export const formFieldTypeMultipleSelect = "multipleSelect";
export const formFieldTypeSingleSelect = "singleSelect";
export const formFieldTypeDate = "date";
export const formFieldTypeDatetime = "datetime";
export const formFieldTypeUrl = "url";
export const formFieldTypeEmail = "email";
export const formFieldTypeNumber = "number";

export const fieldTypes = {
  [formFieldTypeSingleLineText]: {
    title: "Text",
    icon: TextFormatIcon,
    component: FormFieldSingleLineText,
  },
  [formFieldTypeLongText]: {
    title: "Dlouhý text",
    icon: ViewHeadlineIcon,
    component: FormFieldLongText,
  },
  [formFieldTypeCheckbox]: {
    title: "Zaškrtávací pole",
    icon: CheckBoxIcon,
    component: FormFieldCheckbox,
  },
  [formFieldTypeSingleSelect]: {
    title: "Výběr ze seznamu (jedna možnost)",
    icon: ListIcon,
    component: FormFieldSingleSelect,
  },
  [formFieldTypeMultipleSelect]: {
    title: "Výběr ze seznamu (více možností)",
    icon: ListAltIcon,
    component: FormFieldMultipleSelect,
  },
  [formFieldTypeDate]: {
    title: "Datum",
    icon: CalendarTodayIcon,
    component: FormFieldDate,
  },
  [formFieldTypeDatetime]: {
    title: "Datum a čas",
    icon: TodayIcon,
    component: FormFieldDateTime,
  },
  [formFieldTypeUrl]: {
    title: "URL",
    icon: LinkIcon,
    component: FormFieldUrl,
  },
  [formFieldTypeEmail]: {
    title: "E-mail",
    icon: EmailIcon,
    component: FormFieldEmail,
  },
  [formFieldTypeNumber]: {
    title: "Číslo",
    icon: Filter1Icon,
    component: FormFieldNumber,
  },
};

export const fieldVisibilities = {
  show: { title: "ANO" },
  hide: { title: "NE" },
  // custom: { title: "Dle nastavení člena" },
};
