import axios from "axios";
import { removeAuthToken } from "./localStorage";

export const authTryLogin = async (
  credentials,
  successCallback,
  failureCallback
) => {
  axios
    .post(
      process.env.REACT_APP_ENV_ENDPOINT_SERVER + "/authentication_token",
      credentials
    )
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      failureCallback(error);
    });
};

export const authLogout = async (dispatch) => {
  await removeAuthToken();
  await authRedirectToLoginPage();
};

export const authRedirectToHomePage = () => {
  window.location.href = "/member";
};

export const authRedirectToLoginPage = () => {
  window.location.href = "/login";
};
