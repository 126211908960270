import { applyMiddleware, createStore } from "redux";
import { rootReducer } from "../reducers/rootReducer";
import { logger } from "redux-logger/src";
import { api } from "../api/api";
import thunk from "redux-thunk";

export const store = createStore(
  rootReducer,
  applyMiddleware(thunk, api.middleware, logger)
);
