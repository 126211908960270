import React from "react";
import {
  TYPE_CPD_YEAR,
  useGetCpdHourTypeAllQuery,
  useGetCpdYearAllQuery,
} from "../../api/api";
import { formSet } from "../../actions/appActions";
import { useDispatch } from "react-redux";
import { Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import DataTable from "../DataTable/DataTable";

const CpdYearList = () => {
  const dispatch = useDispatch();

  const yearsData = useGetCpdYearAllQuery();
  const hourTypesData = useGetCpdHourTypeAllQuery();

  const years = yearsData.data?.["hydra:member"] || [];
  const hourTypes = hourTypesData.data?.["hydra:member"] || [];

  const isLoading = yearsData.isLoading || hourTypesData.isLoading;

  const prepareRowToForm = (row) => {
    const data = { ...row };
    row.minimalRequirements.forEach((req) => {
      data["minRequirement_" + req.type_id] = req.value;
    });

    return data;
  };

  const columns = [
    {
      field: "title",
      flex: 2,
      headerName: "Rok",
    },
    {
      field: "actions",
      flex: 1,
      headerName: "Akce",
      disableColumnMenu: true,
      type: "number",
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() => {
                dispatch(formSet(TYPE_CPD_YEAR, prepareRowToForm(params.row)));
              }}
              color="primary"
              size="small"
            >
              <Edit fontSize="inherit" />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <>
      <DataTable
        columns={columns}
        rows={years}
        autoHeight={true}
        loading={isLoading}
      />
    </>
  );
};

export default CpdYearList;
