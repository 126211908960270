import React from "react";
import {
  formFieldTypeCheckbox,
  formFieldTypeMultipleSelect,
  formFieldTypeSingleLineText,
  formFieldTypeSingleSelect,
} from "../../constants/enum";
import { FORM_MODE_CREATE, FORM_MODE_UPDATE } from "../../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFormByFormNameIsOpen,
  selectFormByFormNameMode,
} from "../../selectors/selectors";
import {
  TYPE_MEMBER,
  useCreateMemberMutation,
  useGetMemberFieldsAllQuery,
  useGetMemberGroupsAllQuery,
  useUpdateMemberMutation,
} from "../../api/api";
import { appSnackbarAdd, formSet } from "../../actions/appActions";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { AddCircle, Save } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import FormField from "../Form/FormField";
import { store } from "../../store";

const formName = TYPE_MEMBER;

const sortFnByPropertyName = (propertyName) => (a, b) => {
  const aX = a.hasOwnProperty(propertyName) ? a[propertyName] : 999999;
  const bX = b.hasOwnProperty(propertyName) ? b[propertyName] : 999999;

  return aX - bX;
};

const getFormFields = (mode, { memberGroups = [], memberFields = [] }) => {
  const fields = {};
  fields.enabled = {
    formFieldType: formFieldTypeCheckbox,
    label: "Aktivní člen?",
  };
  fields.showInContacts = {
    formFieldType: formFieldTypeCheckbox,
    label: "Zobrazovat úplný profil v adresáři?",
  };
  fields.email = {
    formFieldType: formFieldTypeSingleLineText,
    label: "E-mail",
    fullWidth: true,
  };
  if (mode === FORM_MODE_CREATE) {
    fields.password = {
      formFieldType: formFieldTypeSingleLineText,
      label: "Heslo",
      fullWidth: true,
      type: "password",
    };
  }
  fields.memberGroups = {
    formFieldType: formFieldTypeMultipleSelect,
    options: memberGroups.map((v) => {
      return v.id;
    }),
    getOptionValue: (option) => {
      return option;
    },
    getOptionLabel: (option) => {
      return memberGroups.find((memberGroup) => memberGroup.id === option)
        .title;
    },
    label: "Skupiny",
    fullWidth: true,
  };

  for (let memberField of [...memberFields].sort(
    sortFnByPropertyName("position")
  )) {
    const field = {
      inValues: true, //nedávat false -> invalues = true, znamená, že hodnoty tohoto pole se budou zapisovat do objektu values uvnitř objektu form
      formFieldType: memberField.type,
      label: memberField.title + (memberField.required ? " *" : ""),
      // required: memberField.required,
      fullWidth: true,
      // error: errors.hasOwnProperty(memberField.id),
      // helperText: errors.hasOwnProperty(memberField.id)
      //     ? errors[memberField.id]
      //     : "",
    };
    switch (memberField.type) {
      case formFieldTypeSingleSelect:
      case formFieldTypeMultipleSelect:
        {
          // console.log(memberField.options);
          field.options = memberField.options;
          field.getOptionValue = (option) => {
            if (typeof option === "object") {
              return option.id;
            }
            return option;
          };
          field.getOptionLabel = (option) => {
            if (typeof option === "object") {
              return option.title;
            }
            const index = memberField.options.findIndex((o) => {
              return o.id === option;
            });
            if (index > -1) {
              return memberField.options[index].title;
            }
            return "";
            // return option.title;
          };
        }
        break;
      default: {
      }
    }
    fields[memberField.id] = field;
  }

  return fields;
};

const MemberDetail = () => {
  const dispatch = useDispatch();

  const open = useSelector((state) =>
    selectFormByFormNameIsOpen(state, { formName })
  );
  const mode = useSelector((state) =>
    selectFormByFormNameMode(state, { formName })
  );

  const memberGroupsData = useGetMemberGroupsAllQuery();
  const memberFieldsData = useGetMemberFieldsAllQuery();

  const memberGroups = memberGroupsData.data?.["hydra:member"] || [];
  const memberFields = memberFieldsData.data?.["hydra:member"] || [];

  const [create, createResult] = useCreateMemberMutation();
  const [update, updateResult] = useUpdateMemberMutation();

  const handleClose = () => {
    dispatch(formSet(formName, null));
  };

  const [errors, setErrors] = React.useState({});

  // const validateForm = () => {
  //   const errors = {};
  //   let isValid = true;
  //   for (let memberField of memberFields) {
  //     if (
  //       memberField.required &&
  //       (!form.hasOwnProperty(memberField.id) ||
  //         form[memberField.id].trim() === "")
  //     ) {
  //       errors[memberField.id] = "Toto pole je povinné.";
  //       isValid = false;
  //     }
  //   }
  //   setErrors(errors);
  //
  //   return isValid;
  // };

  const handleSubmit = () => {
    const form = store.getState().app.forms[formName];

    // console.log(form.values);
    // return;

    const values = {};
    Object.keys(form.values).forEach((key) => {
      const v = form.values[key];
      if (typeof v === "object") {
        values[key] = v.id;
      } else {
        values[key] = v;
      }
    });
    const data = { ...form, values: values };

    if (mode === FORM_MODE_CREATE) {
      create({ body: data });
    }
    if (mode === FORM_MODE_UPDATE) {
      update({ body: data, id: data.id });
    }
  };

  const disabled = updateResult.isLoading || createResult.isLoading;

  React.useEffect(() => {
    if (
      !!createResult &&
      !createResult.isLoading &&
      !createResult.isError &&
      createResult.isSuccess
    ) {
      dispatch(
        appSnackbarAdd({
          message: "Člen byl vytvořen.",
          variant: "success",
        })
      );
      handleClose();
    }
    if (
      !!createResult &&
      !createResult.isLoading &&
      createResult.isError &&
      !createResult.isSuccess
    ) {
      dispatch(
        appSnackbarAdd({
          message: "Člen nebyl vytvořen.",
          variant: "error",
        })
      );
    }
  }, [createResult]);

  React.useEffect(() => {
    if (
      !!updateResult &&
      !updateResult.isLoading &&
      !updateResult.isError &&
      updateResult.isSuccess
    ) {
      dispatch(
        appSnackbarAdd({
          message: "Člen byl upraven.",
          variant: "success",
        })
      );
      handleClose();
    }
    if (
      !!updateResult &&
      !updateResult.isLoading &&
      updateResult.isError &&
      !updateResult.isSuccess
    ) {
      dispatch(
        appSnackbarAdd({
          message: "Člen nebyl upraven.",
          variant: "error",
        })
      );
    }
  }, [updateResult]);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {mode === FORM_MODE_CREATE ? "Vytvořit člena" : "Upravit člena"}
          <IconButton
            sx={(theme) => {
              return {
                position: "absolute",
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              };
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {open && (
            <>
              {Object.entries(
                getFormFields(mode, { memberGroups, memberFields })
              ).map((v) => {
                const [name, field] = v;
                return (
                  <FormField
                    key={name}
                    formName={formName}
                    name={name}
                    {...field}
                  />
                );
              })}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disableElevation
            startIcon={mode === FORM_MODE_CREATE ? <AddCircle /> : <Save />}
            disabled={disabled}
          >
            {mode === FORM_MODE_CREATE ? "Vytvořit člena" : "Upravit člena"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MemberDetail;
