import React from "react";
import { useDispatch } from "react-redux";
import { formSet } from "../../actions/appActions";
import { initialMemberGroup } from "../../constants/factories";
import { AddCircle } from "@mui/icons-material";
import { Button } from "@mui/material";
import { TYPE_MEMBER_GROUP } from "../../api/api";
import MemberGroupList from "./MemberGroupList";

const MemberGroup = () => {
  const dispatch = useDispatch();

  const handleCreate = () => {
    dispatch(formSet(TYPE_MEMBER_GROUP, { ...initialMemberGroup }));
  };

  return (
    <>
      <Button
        sx={{ mt: 2, mb: 2 }}
        onClick={handleCreate}
        color="primary"
        variant="contained"
        disableElevation
        startIcon={<AddCircle />}
      >
        Vytvořit skupinu členství
      </Button>
      <MemberGroupList />
    </>
  );
};

export default MemberGroup;
