import React from "react";
import { RemoveCircle } from "@mui/icons-material";
import FormFieldSingleLineText from "../FormFieldSingleLineText";
import { Box, IconButton } from "@mui/material";

const FormFieldSelectAdditionalRender = (props) => {
  const { options, handleChangeOption, handleRemoveOption, handleAddOption } =
    props;

  React.useEffect(() => {
    handleAddOption();
  }, []);

  return (
    <Box mr={2} ml={2}>
      {options.map((option, key) => {
        return (
          <Box key={option.id} display={"flex"} alignItems={"center"}>
            <Box flexGrow={1}>
              <FormFieldSingleLineText
                name={option.id}
                value={option.title}
                onChange={handleChangeOption}
                size={"small"}
                margin={"dense"}
                fullWidth={true}
              />
            </Box>
            <Box>
              <IconButton
                size={"small"}
                color={"secondary"}
                onClick={() => {
                  handleRemoveOption(option.id);
                }}
                disabled={key + 1 === options.length}
              >
                <RemoveCircle />
              </IconButton>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default FormFieldSelectAdditionalRender;
