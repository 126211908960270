import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {},
}));

const UnsecuredLayout = (props) => {
  const { children } = props;

  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};

export default UnsecuredLayout;
