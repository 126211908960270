import { TextField } from "@mui/material";
import { textFieldMargin, textFieldVariant } from "../../../theme/theme";
import React from "react";

const FormFieldNumber = (props) => {
  return (
    <TextField
      type={"number"}
      margin={textFieldMargin}
      variant={textFieldVariant}
      {...props}
    />
  );
};

export default FormFieldNumber;
