import React from "react";
import {
  TYPE_PAYMENT_V0,
  useGetMembersAllQuery,
  useGetPaymentOrderTypesAllQuery,
  useGetPaymentV0sAllQuery,
} from "../../api/api";
import { formSet } from "../../actions/appActions";
import { useDispatch } from "react-redux";
import { Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import DataTable from "../DataTable/DataTable";
import { formatDate } from "../../utils/utils";

const PaymentV0List = () => {
  const dispatch = useDispatch();

  const membersData = useGetMembersAllQuery();
  const paymentOrderTypesData = useGetPaymentOrderTypesAllQuery();
  const paymentV0sData = useGetPaymentV0sAllQuery();

  const members = membersData.data?.["hydra:member"] || [];
  const paymentOrderTypes = paymentOrderTypesData.data?.["hydra:member"] || [];
  const paymentV0s = paymentV0sData.data?.["hydra:member"] || [];

  const isLoading =
    paymentV0sData.isLoading ||
    membersData.isLoading ||
    paymentOrderTypesData.isLoading;

  const columns = [
    {
      field: "member",
      flex: 2,
      headerName: "Člen",
      valueGetter: (params) => {
        const collection = members;
        const objectPropertyName = "member";
        const nestedObjectPropertyName = "email";
        const index = collection.findIndex((v) => {
          return v["@id"] === params.row[objectPropertyName];
        });
        if (index === -1) {
          return "";
        }
        return collection[index][nestedObjectPropertyName];
      },
    },
    {
      field: "type",
      flex: 2,
      headerName: "Typ",
      valueGetter: (params) => {
        const collection = paymentOrderTypes;
        const objectPropertyName = "type";
        const nestedObjectPropertyName = "title";
        const index = collection.findIndex((v) => {
          return v["@id"] === params.row[objectPropertyName];
        });
        if (index === -1) {
          return "";
        }
        return collection[index][nestedObjectPropertyName];
      },
    },
    {
      field: "validFrom",
      flex: 1,
      headerName: "Od",
      valueGetter: (params) => {
        return formatDate(params.row.validFrom);
      },
    },
    {
      field: "validTo",
      flex: 1,
      headerName: "Do",
      valueGetter: (params) => {
        return formatDate(params.row.validTo);
      },
    },
    {
      field: "amount",
      flex: 1,
      headerName: "Částka",
    },
    {
      field: "status",
      flex: 1,
      headerName: "Stav",
    },
    {
      field: "paidAt",
      flex: 1,
      headerName: "Zaplaceno",
      valueGetter: (params) => {
        return formatDate(params.row.paidAt);
      },
    },
    {
      field: "variableSymbol",
      flex: 1,
      headerName: "VS",
    },
    {
      field: "specificSymbol",
      flex: 1,
      headerName: "SS",
    },
    {
      field: "note",
      flex: 1,
      headerName: "Poznámka",
    },
    {
      field: "actions",
      flex: 1,
      headerName: "Akce",
      disableColumnMenu: true,
      type: "number",
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() => {
                dispatch(formSet(TYPE_PAYMENT_V0, params.row));
              }}
              color="primary"
              size="small"
            >
              <Edit fontSize="inherit" />
            </IconButton>
            {/*<MemberDeleteAction member={params.row} onSubmit={handleSubmit} />*/}
          </>
        );
      },
    },
  ];
  //
  return (
    <>
      <DataTable
        columns={columns}
        rows={paymentV0s}
        autoHeight={true}
        loading={isLoading}
      />
    </>
  );
};

export default PaymentV0List;
