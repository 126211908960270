import React from "react";
import { makeStyles } from "@mui/styles";
import { Container, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  footer: {
    display: "block",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    // background: "#002e34",
    zIndex: theme.zIndex.drawer + 1,
    position: "relative",
  },
  footerGridRight: {
    textAlign: "right",
    position: "relative",

    [theme.breakpoints.down("xs")]: {
      order: 0,
      marginBottom: theme.spacing(1),
      textAlign: "left",
    },
  },
  footerGridLeft: {
    [theme.breakpoints.down("xs")]: {
      order: 1,
    },
  },
  nodeEnvStatus: {
    color: "#ed1a3b",
    display: "block",
    wordWrap: "break-word",
    fontSize: 12,
  },
  contactSupport: {},
  version: {
    color: "#66788a",
    fontSize: 14,

    "& code": {
      fontSize: 12,
    },
  },
  copy: {
    color: "#66788a",
  },
}));

const Footer = (props) => {
  const { logged } = props;
  const classes = useStyles();

  return (
    <Container maxWidth={false} className={classes.footer}>
      <Typography className={classes.version} variant="body1">
        Actuaria <code>{process.env.REACT_APP_VERSION}</code>
      </Typography>
      {/* <Typography className={classes.copy} variant="caption">
        &copy; {new Date().getFullYear()} Query Digital, s.r.o., všechna práva vyhrazena
      </Typography> */}
      {process.env.REACT_APP_ENV === "development" ? (
        <p>
          <code className={classes.nodeEnvStatus}>
            <span role="img" aria-label="environment">
              ☁️
            </span>{" "}
            {process.env.REACT_APP_ENV}
          </code>
        </p>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default Footer;
