import { Box, Container, Hidden, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Footer from "./Footer/Footer";
import PeopleIcon from "@mui/icons-material/People";
import PaymentIcon from "@mui/icons-material/Payment";
import ToolbarProfile from "../../components/ToolbarProfile/ToolbarProfile";
import SecuredLayoutMenu from "./SecuredLayoutMenu";
import { Helmet } from "react-helmet-async";
import { useGetProfileQuery } from "../../api/api";
import { readAuthToken } from "../../auth/localStorage";
import { authRedirectToLoginPage } from "../../auth/auth";
import { School, Summarize } from "@mui/icons-material";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 200,
    height: "auto",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  toolbar: {
    background: "#002e34",
    minHeight: 56,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingRight: "10px",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      minHeight: 64,
    },
  },
  toolbarRight: {
    marginLeft: "auto",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  userInfo: {
    color: "#fff",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textAlign: "right",
  },
  avatar: {
    display: "flex",
    marginLeft: theme.spacing(1),
  },

  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    background: "#002e34",
    boxShadow: "none",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      background:
        "#002e34 url('images/ship.svg') no-repeat right 200px bottom -15px",
      backgroundSize: "200px auto",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  drawerPaper: {
    width: drawerWidth,
    border: 0,
  },
  container: {
    flexGrow: 1,
    overflow: "hidden",
    margin: theme.spacing(9, 0, 3, 0),
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(11, 0, 3, 0),
    },
  },
  listItem: {
    "& .MuiSvgIcon-root": {
      transition: "color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    "&:hover": {
      background: "#00afdb",
      color: "#fff",
      transition:
        "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      "& .MuiSvgIcon-root": {
        color: "#fff",
      },
    },
  },
  listItemText: {
    fontSize: 14,
    textTransform: "uppercase",
  },
  listItemActive: {
    background: "#00afdb",
    color: "#fff",

    "& .MuiSvgIcon-root": {
      color: "#fff",
    },
  },
  profileIcon: {
    color: "#fff",
  },
}));

const drawerWidth = 240;

const handleExport = (urlPath, filename) => {
  const url = process.env.REACT_APP_ENV_ENDPOINT_SERVER + urlPath;
  const authToken = readAuthToken();
  axios
    .get(url, {
      responseType: "blob",
      headers: { Authorization: `Bearer ${authToken}` },
    })
    .then((response) => {
      const href = URL.createObjectURL(response.data);

      const dt = new Date();

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        filename + " - " + dt.toLocaleDateString() + ".xlsx"
      ); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

const list = [
  {
    title: "Členové",
    icon: <PeopleIcon />,
    list: [
      { title: "Členové", path: "/member" },
      {
        title: "Skupiny členství",
        path: "/member/group",
      },
      {
        title: "Vlastnosti členů",
        path: "/member/field",
      },
    ],
  },
  {
    title: "Platby",
    icon: <PaymentIcon />,
    list: [
      // {
      //   title: "Párování plateb",
      //   path: "/payment_order_payment_pairing",
      // },
      {
        title: "Platby",
        path: "/payment",
      },
      // {
      //   title: "Předpisy plateb",
      //   path: "/payment_order",
      // },
      {
        title: "Typy plateb",
        path: "/payment_order_type",
      },
    ],
  },
  {
    title: "CPD",
    icon: <School />,
    list: [
      {
        title: "Akce",
        path: "/cpd_event",
      },
      {
        title: "Účasti",
        path: "/cpd_event_attendee",
      },
      {
        title: "Roční minimální požadavky",
        path: "/cpd_year",
      },
      {
        title: "Kategorie akcí",
        path: "/cpd_event_category",
      },
    ],
  },
  {
    title: "Reporty XLSX",
    icon: <Summarize />,
    list: [
      {
        title: "Seznam členů",
        onClick: () => {
          handleExport("/export/members", "Seznam členů");
        },
      },
      {
        title: "Seznam plateb",
        onClick: () => {
          handleExport("/export/payments", "Seznam plateb");
        },
      },
      {
        title: "Seznam CPD akcí",
        onClick: () => {
          handleExport("/export/cpdEvents", "Seznam CPD kcí");
        },
      },
      {
        title: "Seznam CPD účastí",
        onClick: () => {
          handleExport("/export/cpdEventsAttendees", "Seznam CPD účastí");
        },
      },
    ],
  },
];

const SecuredLayout = (props) => {
  const { window } = props;

  const { data: profile = null } = useGetProfileQuery();

  const { title = "Administrace", children } = props;
  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (!readAuthToken()) {
    authRedirectToLoginPage();
  }

  if (!!profile && !profile.roles.includes("ROLE_ADMIN")) {
    authRedirectToLoginPage();
  }

  if (!profile) {
    return <></>;
  }

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <img
          alt="Actuaria"
          className={classes.logo}
          src="/images/actuaria-logo-blue.svg"
        />
      </div>
      <Divider />
      <SecuredLayoutMenu list={list} classes={classes} />
      <Divider />
      <Footer />
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <div className={classes.toolbarRight}>
            <ToolbarProfile />
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>

      <Container className={classes.container} maxWidth={"xl"}>
        <Helmet>
          <title>{title} — Actuaria</title>
        </Helmet>
        <Paper elevation={0}>
          <Box p={3}>
            <Typography variant="h1">{title}</Typography>
            {children}
          </Box>
        </Paper>
      </Container>
    </div>
  );
};

export default SecuredLayout;
