import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { Slide, StyledEngineProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Router from "./routes/Router";
import { theme } from "./theme/theme";
import Notifier from "./components/Notifier/Notifier";
import { HelmetProvider } from "react-helmet-async";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import "moment/locale/cs";
import { SnackbarProvider } from "notistack";
import "@fontsource/source-sans-pro";
import MemberDetail from "./components/Member/MemberDetail";
import MemberGroupDetail from "./components/MemberGroup/MemberGroupDetail";
import MemberFieldDetail from "./components/MemberField/MemberFieldDetail";
import PaymentOrderTypeDetail from "./components/PaymentOrderType/PaymentOrderTypeDetail";
import { cs } from "date-fns/locale";
import PaymentV0Detail from "./components/PaymentV0/PaymentV0Detail";
import CpdEventCategoryDetail from "./components/CpdEventCategory/CpdEventCategoryDetail";
import CpdYearDetail from "./components/CpdYear/CpdYearDetail";
import CpdEventDetail from "./components/CpdEvent/CpdEventDetail";
import CpdEventAttendeeDetail from "./components/CpdEventAttendee/CpdEventAttendeeDetail";

function App() {
  moment.locale("cs");

  return (
    <HelmetProvider>
      <LocalizationProvider
        // utils={MomentUtils}
        // libInstance={moment}
        locale={cs}
        dateAdapter={AdapterDateFns}
      >
        <ThemeProvider theme={theme}>
          <StyledEngineProvider injectFirst>
            <SnackbarProvider
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              TransitionComponent={Slide}
            >
              <CssBaseline />
              <Router />
              <MemberDetail />
              <MemberGroupDetail />
              <MemberFieldDetail />
              <PaymentOrderTypeDetail />
              <PaymentV0Detail />
              {/*<PaymentDetail />*/}
              {/*<PaymentOrderDetail />*/}
              {/*<PaymentPaymentOrderDetail />*/}
              <CpdEventCategoryDetail />
              <CpdYearDetail />
              <CpdEventDetail />
              <CpdEventAttendeeDetail />
              <Notifier />
            </SnackbarProvider>
          </StyledEngineProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </HelmetProvider>
  );
}

export default App;
