const AUTH_TOKEN = "auth_token";

export const storeAuthToken = (token) => {
  localStorage.setItem(AUTH_TOKEN, token);
};

export const readAuthToken = () => {
  return localStorage.getItem(AUTH_TOKEN);
};

export const removeAuthToken = () => {
  localStorage.removeItem(AUTH_TOKEN);
};
