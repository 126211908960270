import React from "react";
import {
  TYPE_PAYMENT_ORDER_TYPE,
  useGetPaymentOrderTypesAllQuery,
} from "../../api/api";
import { formSet } from "../../actions/appActions";
import { useDispatch } from "react-redux";
import { Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import DataTable from "../DataTable/DataTable";

const PaymentOrderTypeList = () => {
  const dispatch = useDispatch();

  const paymentOrderTypesData = useGetPaymentOrderTypesAllQuery();

  const paymentOrderTypes = paymentOrderTypesData.data?.["hydra:member"] || [];

  const isLoading = paymentOrderTypesData.isLoading;

  const columns = [
    {
      field: "title",
      flex: 3,
      headerName: "Název",
    },
    {
      field: "code",
      flex: 2,
      headerName: "Kód",
    },
    {
      field: "actions",
      flex: 1,
      headerName: "Akce",
      disableColumnMenu: true,
      type: "number",
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() => {
                dispatch(formSet(TYPE_PAYMENT_ORDER_TYPE, params.row));
              }}
              color="primary"
              size="small"
            >
              <Edit fontSize="inherit" />
            </IconButton>
            {/*<MemberDeleteAction member={params.row} onSubmit={handleSubmit} />*/}
          </>
        );
      },
    },
  ];
  //
  return (
    <>
      <DataTable
        columns={columns}
        rows={paymentOrderTypes}
        autoHeight={true}
        loading={isLoading}
      />
    </>
  );
};

export default PaymentOrderTypeList;
