import React from "react";
import MemberList from "./MemberList";
import { useDispatch } from "react-redux";
import { formSet } from "../../actions/appActions";
import { initialMember } from "../../constants/factories";
import { AddCircle } from "@mui/icons-material";
import { Button } from "@mui/material";
import { TYPE_MEMBER } from "../../api/api";

const Member = () => {
  const dispatch = useDispatch();

  const handleCreate = () => {
    dispatch(formSet(TYPE_MEMBER, { ...initialMember }));
  };

  return (
    <>
      <Button
        sx={{ mt: 2, mb: 2 }}
        onClick={handleCreate}
        color="primary"
        variant="contained"
        disableElevation
        startIcon={<AddCircle />}
      >
        Vytvořit člena
      </Button>
      <MemberList />
    </>
  );
};

export default Member;
