import {
  APP_SNACKBAR_ADD,
  APP_SNACKBAR_REMOVE,
  FORM_SET,
} from "../constants/actionTypes";

export const formSet = (formName, data) => (dispatch) => {
  dispatch({
    type: FORM_SET,
    payload: {
      formName,
      data,
    },
  });
};

export const appSnackbarAdd = (notification) => (dispatch) => {
  const n = {
    message: notification.message,
    key: new Date().getTime() + Math.random(),
    options: {
      variant: notification.variant,
      severity: notification.variant,
    },
  };

  dispatch({
    type: APP_SNACKBAR_ADD,
    payload: n,
  });
};

export const appSnackbarRemove = (key) => ({
  type: APP_SNACKBAR_REMOVE,
  payload: {
    key,
  },
});
