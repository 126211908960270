import { createTheme } from "@mui/material/styles";

export const textFieldVariant = "outlined";
export const textFieldMargin = "normal";
export const tableSize = "small"; // medium

export const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#ffffff",
      main: "#00afdb",
    },
    text: {
      primary: "#333",
    },
    background: {
      default: "#eef8f9",
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
    fontSize: 15,
    h1: {
      fontSize: 34,
    },
    h2: {
      fontSize: 22,
    },
  },
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiTableCell: {
      head: {
        fontWeight: 600,
      },
      body: {
        "& strong": {
          fontWeight: 600,
        },
      },
    },
    MuiTableRow: {
      // root: {
      //   "&:last-of-type": {
      //     "& th, & td": {
      //       borderBottom: 0,
      //     }
      //   }
      // }
      // hover: {
      //   "&:hover": {
      //     backgroundColor: "#e1f5fe !important",
      //   },
      //   "&:hover td": {
      //     backgroundColor: "#e1f5fe !important",
      //   },
      // },
    },
  },
});
