import React from "react";
import {
  APP_SNACKBAR_ADD,
  APP_SNACKBAR_REMOVE,
  FORM_SET,
  FORM_UPDATE,
  USER_EMAIL_SET,
} from "../constants/actionTypes";
import produce from "immer";
import {
  TYPE_MEMBER,
  TYPE_MEMBER_FIELD,
  TYPE_MEMBER_GROUP,
  TYPE_PAYMENT,
  TYPE_PAYMENT_ORDER,
  TYPE_PAYMENT_ORDER_TYPE,
  TYPE_PAYMENT_PAYMENT_ORDER,
  TYPE_PAYMENT_V0,
} from "../api/api";

const initialState = {
  forms: {
    [TYPE_MEMBER]: null,
    [TYPE_MEMBER_GROUP]: null,
    [TYPE_MEMBER_FIELD]: null,
    [TYPE_PAYMENT]: null,
    [TYPE_PAYMENT_ORDER]: null,
    [TYPE_PAYMENT_ORDER_TYPE]: null,
    [TYPE_PAYMENT_PAYMENT_ORDER]: null,
    [TYPE_PAYMENT_V0]: null,
  },
  notifications: [],
  userEmail: "",
};

export const appReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FORM_SET: {
      return produce(state, (draft) => {
        draft.forms[payload.formName] = payload.data;
      });
    }
    case FORM_UPDATE: {
      return produce(state, (draft) => {
        if (payload.inValues) {
          draft.forms[payload.formName]["values"][payload.name] = payload.value;
        } else {
          draft.forms[payload.formName][payload.name] = payload.value;
        }
      });
    }
    case APP_SNACKBAR_ADD: {
      return produce(state, (draft) => {
        draft.notifications.push(payload);
      });
    }
    case APP_SNACKBAR_REMOVE: {
      return produce(state, (draft) => {
        const index = draft.notifications.findIndex(
          (n) => n.key === payload.key
        );
        if (index !== -1) draft.notifications.splice(index, 1);
      });
    }
    case USER_EMAIL_SET: {
      return produce(state, (draft) => {
        draft.userEmail = payload.userEmail;
      });
    }
    default: {
      return state;
    }
  }
};
