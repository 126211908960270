import React from "react";
import {
  TYPE_CPD_EVENT_ATTENDEE,
  useGetCpdEventAllQuery,
  useGetCpdEventAttendeeAllQuery,
  useGetCpdEventCategoryAllQuery,
  useGetCpdHourTypeAllQuery,
  useGetCpdYearAllQuery,
  useGetMembersAllQuery,
} from "../../api/api";
import { formSet } from "../../actions/appActions";
import { useDispatch } from "react-redux";
import { Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import DataTable from "../DataTable/DataTable";

const CpdEventAttendeeList = () => {
  const dispatch = useDispatch();

  const attendeesData = useGetCpdEventAttendeeAllQuery();
  const membersData = useGetMembersAllQuery();
  const eventsData = useGetCpdEventAllQuery();
  const categoriesData = useGetCpdEventCategoryAllQuery();
  const yearsData = useGetCpdYearAllQuery();
  const hourTypesData = useGetCpdHourTypeAllQuery();

  const attendees = attendeesData.data?.["hydra:member"] || [];
  const members = membersData.data?.["hydra:member"] || [];
  const events = eventsData.data?.["hydra:member"] || [];
  const categories = categoriesData.data?.["hydra:member"] || [];
  const years = yearsData.data?.["hydra:member"] || [];
  const hourTypes = hourTypesData.data?.["hydra:member"] || [];

  const isLoading =
    yearsData.isLoading ||
    hourTypesData.isLoading ||
    eventsData.isLoading ||
    categoriesData.isLoading ||
    attendeesData.isLoading ||
    membersData.isLoading;

  const prepareRowToForm = (row) => {
    const data = { ...row };
    row.hours.forEach((req) => {
      data["hour_" + req.type_id] = req.value;
    });

    return data;
  };

  const getColumns = ({ hourTypes }) => {
    const hts = hourTypes
      .filter((ht) => ht.useInAllocation)
      .map((ht) => {
        return {
          field: "hour_" + ht.id,
          flex: 1,
          headerName: ht.title,
          renderCell: (params) => {
            const index = params.row.hours.findIndex(
              (h) => h.type_id === ht.id
            );
            if (index > -1) {
              return <>{params.row.hours[index].value}</>;
            }
            return <></>;
          },
        };
      });

    const columns = [
      {
        field: "memberId",
        flex: 2,
        headerName: "Člen",
        renderCell: (params) => {
          const index = members.findIndex((h) => params.row.memberId === h.id);
          if (index > -1) {
            return <>{members[index].email}</>;
          }
          return <></>;
        },
      },
      {
        field: "eventId",
        flex: 2,
        headerName: "Akce",
        renderCell: (params) => {
          const index = events.findIndex((h) => params.row.eventId === h.id);
          if (index > -1) {
            return <>{events[index].title}</>;
          }
          return <></>;
        },
      },
      {
        field: "yearId",
        flex: 1,
        headerName: "Rok",
        renderCell: (params) => {
          const index = events.findIndex((h) => params.row.eventId === h.id);
          if (index > -1) {
            const index2 = years.findIndex(
              (h2) => events[index].yearId === h2.id
            );
            if (index2 > -1) {
              return <>{years[index2].title}</>;
            }
          }
          return <></>;
        },
      },
    ]
      .concat(hts)
      .concat([
        {
          field: "actions",
          flex: 1,
          headerName: "Akce",
          disableColumnMenu: true,
          type: "number",
          filterable: false,
          sortable: false,
          renderCell: (params) => {
            return (
              <>
                <IconButton
                  onClick={() => {
                    dispatch(
                      formSet(
                        TYPE_CPD_EVENT_ATTENDEE,
                        prepareRowToForm(params.row)
                      )
                    );
                  }}
                  color="primary"
                  size="small"
                >
                  <Edit fontSize="inherit" />
                </IconButton>
              </>
            );
          },
        },
      ]);

    return columns;
  };

  return (
    <>
      <DataTable
        columns={getColumns({ hourTypes })}
        rows={attendees.filter((a) => !a.deleted)}
        autoHeight={true}
        loading={isLoading}
      />
    </>
  );
};

export default CpdEventAttendeeList;
