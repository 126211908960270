import { Autocomplete, TextField } from "@mui/material";
import { textFieldMargin, textFieldVariant } from "../../../theme/theme";
import React from "react";

const FormFieldMultipleSelect = (props) => {
  const { onChange, name, label, value, ...rest } = props;

  const handleChange = (event, newValue) => {
    onChange({ target: { name: name, value: newValue } });
  };

  return (
    <Autocomplete
      {...rest}
      value={!value ? [] : value}
      onChange={handleChange}
      filterSelectedOptions={true}
      multiple
      renderInput={(params) => (
        <TextField
          label={label}
          {...params}
          variant={textFieldVariant}
          margin={textFieldMargin}
        />
      )}
    />
  );
};

export default FormFieldMultipleSelect;
