import { Autocomplete, TextField } from "@mui/material";
import { textFieldMargin, textFieldVariant } from "../../../theme/theme";
import React from "react";

const FormFieldSingleSelect = (props) => {
  // const { options, getOptionLabel, getOptionValue, ...rest } = props;
  //
  // const { options, getOptionLabel, getOptionValue, ...rest } = props;

  const { onChange, name, label, value, getOptionValue, ...rest } = props;

  const handleChange = (event, newValue) => {
    onChange({ target: { name: name, value: newValue } });
  };

  return (
    <Autocomplete
      {...rest}
      value={!value ? "" : value}
      onChange={handleChange}
      filterSelectedOptions={true}
      renderInput={(params) => (
        <TextField
          label={label}
          {...params}
          variant={textFieldVariant}
          margin={textFieldMargin}
        />
      )}
    />
  );

  // return (
  //   <TextField
  //     select
  //     variant={textFieldVariant}
  //     margin={textFieldMargin}
  //     {...rest}
  //     InputLabelProps={{ shrink: true }}
  //   >
  //     {options.map((option, key) => {
  //       return (
  //         <MenuItem key={key} value={getOptionValue(option)}>
  //           {getOptionLabel(option)}
  //         </MenuItem>
  //       );
  //     })}
  //   </TextField>
  // );
};

export default FormFieldSingleSelect;
