import React from "react";
import {
  formFieldTypeCheckbox,
  formFieldTypeDate,
  formFieldTypeLongText,
  formFieldTypeNumber,
  formFieldTypeSingleLineText,
  formFieldTypeSingleSelect,
} from "../../constants/enum";
import { FORM_MODE_CREATE, FORM_MODE_UPDATE } from "../../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFormByFormName,
  selectFormByFormNameIsOpen,
  selectFormByFormNameMode,
} from "../../selectors/selectors";
import {
  TYPE_CPD_EVENT,
  useCreateCpdEventMutation,
  useGetCpdEventAllQuery,
  useGetCpdEventCategoryAllQuery,
  useGetCpdHourTypeAllQuery,
  useGetCpdYearAllQuery,
  useUpdateCpdEventMutation,
} from "../../api/api";
import { appSnackbarAdd, formSet } from "../../actions/appActions";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { AddCircle, Save } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import FormField from "../Form/FormField";
import { store } from "../../store";
import { FORM_UPDATE } from "../../constants/actionTypes";

const formName = TYPE_CPD_EVENT;

const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

const OrganizatorSelector = () => {
  const dispatch = useDispatch();
  const form = useSelector((state) =>
    selectFormByFormName(state, { formName })
  );

  const eventsData = useGetCpdEventAllQuery();
  const events = eventsData.data?.["hydra:member"] || [];

  const organizators = events
    .map((e) => e.organizator)
    .filter(onlyUnique)
    .filter((e) => e !== "")
    .sort();

  const [open, setOpen] = React.useState(false);

  const handleOrganizator = (o) => {
    dispatch({
      type: FORM_UPDATE,
      payload: {
        formName,
        name: "organizator",
        value: o,
        inValues: false,
      },
    });
    setOpen(false);
  };

  if (!form) {
    return <></>;
  }

  return (
    <Box sx={{ mt: 1, mb: 1 }}>
      <Button
        size={"small"}
        variant={"outlined"}
        onClick={() => {
          setOpen(true);
        }}
      >
        Vybrat z číselníku pořadatelů
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>Vybrat z číselníku pořadatelů</DialogTitle>
        <DialogContent>
          {organizators.map((o) => {
            return (
              <Box key={o} sx={{ mb: 1 }}>
                <Button
                  fullWidth={true}
                  sx={{ textAlign: "left", justifyContent: "flex-start" }}
                  onClick={() => {
                    handleOrganizator(o);
                  }}
                  variant={"outlined"}
                >
                  {o}
                </Button>
              </Box>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button
            variant={"outlined"}
            color={"error"}
            size={"small"}
            onClick={() => {
              setOpen(false);
            }}
          >
            Zavřít
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const getFormFields = (mode, { categories, years, hourTypes }) => {
  const fields = {};
  fields.code = {
    formFieldType: formFieldTypeSingleLineText,
    label: "Kód",
    fullWidth: true,
  };
  fields.title = {
    formFieldType: formFieldTypeSingleLineText,
    label: "Název",
    fullWidth: true,
  };
  fields.organizator = {
    formFieldType: formFieldTypeSingleLineText,
    label: "Pořadatel",
    fullWidth: true,
    additionalRender: <OrganizatorSelector />,
  };
  fields.place = {
    formFieldType: formFieldTypeSingleLineText,
    label: "Místo",
    fullWidth: true,
  };
  fields.speaker = {
    formFieldType: formFieldTypeSingleLineText,
    label: "Přednášející",
    fullWidth: true,
  };
  fields.dateFrom = {
    formFieldType: formFieldTypeDate,
    label: "Datum začátku akce",
    fullWidth: true,
  };
  fields.description = {
    formFieldType: formFieldTypeLongText,
    label: "Popis",
    fullWidth: true,
  };
  fields.categoryId = {
    formFieldType: formFieldTypeSingleSelect,
    label: "Kategorie",
    fullWidth: true,
    options: categories.map((v) => {
      return v.id;
    }),
    getOptionValue: (option) => {
      return option;
    },
    getOptionLabel: (option) => {
      const index = categories.findIndex((v) => v["id"] === option);
      if (index > -1) {
        return categories[index].title;
      }
      return option;
    },
  };
  hourTypes
    .filter((ht) => ht.useInSubsidization)
    .forEach((ht) => {
      fields["hour_" + ht.id] = {
        formFieldType: formFieldTypeNumber,
        label: "Maximální dotace: " + ht.title,
        fullWidth: true,
      };
    });
  fields.yearId = {
    formFieldType: formFieldTypeSingleSelect,
    label: "Rok",
    fullWidth: true,
    options: years.map((v) => {
      return v.id;
    }),
    getOptionValue: (option) => {
      return option;
    },
    getOptionLabel: (option) => {
      const index = years.findIndex((v) => v["id"] === option);
      if (index > -1) {
        return years[index].title;
      }
      return option;
    },
  };
  fields.dateApproval = {
    formFieldType: formFieldTypeDate,
    label: "Datum schválení akce",
    fullWidth: true,
  };
  fields.locked = {
    formFieldType: formFieldTypeCheckbox,
    label: "Uzamčeno",
    fullWidth: true,
  };

  return fields;
};

const CpdEventDetail = () => {
  const dispatch = useDispatch();

  const open = useSelector((state) =>
    selectFormByFormNameIsOpen(state, { formName })
  );
  const mode = useSelector((state) =>
    selectFormByFormNameMode(state, { formName })
  );

  const hourTypesData = useGetCpdHourTypeAllQuery();
  const categoriesData = useGetCpdEventCategoryAllQuery();
  const yearsData = useGetCpdYearAllQuery();

  const categories = categoriesData.data?.["hydra:member"] || [];
  const years = yearsData.data?.["hydra:member"] || [];
  const hourTypes = hourTypesData.data?.["hydra:member"] || [];

  const [create, createResult] = useCreateCpdEventMutation();
  const [update, updateResult] = useUpdateCpdEventMutation();

  const handleClose = () => {
    dispatch(formSet(formName, null));
  };

  const [errors, setErrors] = React.useState({});

  const handleSubmit = () => {
    const form = { ...store.getState().app.forms[formName] };

    const body = { ...form };

    body.hours = Object.keys(form)
      .filter((key) => {
        return key.startsWith("hour_");
      })
      .map((key) => {
        const ids = key.split("_");
        return {
          type_id: ids[1],
          value: form[key] * 1,
        };
      });

    if (mode === FORM_MODE_CREATE) {
      create({ body: body });
    }
    if (mode === FORM_MODE_UPDATE) {
      body.id = form.id;
      update({ body: body, id: body.id });
    }
  };

  const disabled = updateResult.isLoading || createResult.isLoading;

  React.useEffect(() => {
    if (
      !!createResult &&
      !createResult.isLoading &&
      !createResult.isError &&
      createResult.isSuccess
    ) {
      dispatch(
        appSnackbarAdd({
          message: "Akce byla vytvořena.",
          variant: "success",
        })
      );
      handleClose();
    }
    if (
      !!createResult &&
      !createResult.isLoading &&
      createResult.isError &&
      !createResult.isSuccess
    ) {
      dispatch(
        appSnackbarAdd({
          message: "Akce nebyla vytvořena.",
          variant: "error",
        })
      );
    }
  }, [createResult]);

  React.useEffect(() => {
    if (
      !!updateResult &&
      !updateResult.isLoading &&
      !updateResult.isError &&
      updateResult.isSuccess
    ) {
      dispatch(
        appSnackbarAdd({
          message: "Akce byla upravena.",
          variant: "success",
        })
      );
      handleClose();
    }
    if (
      !!updateResult &&
      !updateResult.isLoading &&
      updateResult.isError &&
      !updateResult.isSuccess
    ) {
      dispatch(
        appSnackbarAdd({
          message: "Akce nebyla upravena.",
          variant: "error",
        })
      );
    }
  }, [updateResult]);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {mode === FORM_MODE_CREATE ? "Vytvořit akci" : "Upravit akci"}
          <IconButton
            sx={(theme) => {
              return {
                position: "absolute",
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              };
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {open && (
            <>
              {Object.entries(
                getFormFields(mode, { hourTypes, categories, years })
              ).map((v) => {
                const [name, field] = v;
                return (
                  <FormField
                    key={name}
                    formName={formName}
                    name={name}
                    {...field}
                  />
                );
              })}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disableElevation
            startIcon={mode === FORM_MODE_CREATE ? <AddCircle /> : <Save />}
            disabled={disabled}
          >
            {mode === FORM_MODE_CREATE ? "Vytvořit akci" : "Upravit akci"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CpdEventDetail;
