import { useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import { authLogout } from "../../auth/auth";
import Gravatar from "react-gravatar";
import { useGetProfileQuery } from "../../api/api";

const useStyles = makeStyles((theme) => ({
  userInfo: {
    color: "#fff",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textAlign: "right",
  },
  avatar: {
    display: "flex",
    marginLeft: theme.spacing(1),
    borderRadius: "50%",
  },
  profileIcon: {
    color: "#fff",
  },
}));

const ToolbarProfile = () => {
  const classes = useStyles();

  const { data: profile = null } = useGetProfileQuery();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = async () => {
    handleClose();
    await authLogout(dispatch);
  };

  if (!profile) {
    return <></>;
  }

  return (
    <>
      <div className={classes.userInfo}>
        <Typography variant="subtitle2">{profile.email}</Typography>
      </div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        <MoreVertIcon className={classes.profileIcon} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={logOut}>
          <ListItemIcon className={classes.userMenuIcon}>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Odhlásit" />
        </MenuItem>
      </Menu>
      <Gravatar
        email={profile.email}
        default={"robohash"}
        size={50} //default: 50
        className={classes.avatar}
      />
      {/*<Avatar className={classes.avatar} style={{ backgroundColor: "#00afdb" }}>*/}
      {/*  XY*/}
      {/*</Avatar>*/}
    </>
  );
};

export default ToolbarProfile;
