import React from "react";
import {
  TYPE_CPD_EVENT,
  useGetCpdEventAllQuery,
  useGetCpdEventCategoryAllQuery,
  useGetCpdHourTypeAllQuery,
  useGetCpdYearAllQuery,
} from "../../api/api";
import { formSet } from "../../actions/appActions";
import { useDispatch } from "react-redux";
import { Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import DataTable from "../DataTable/DataTable";
import { formatDate } from "../../utils/utils";

const CpdEventList = () => {
  const dispatch = useDispatch();

  const eventsData = useGetCpdEventAllQuery();
  const categoriesData = useGetCpdEventCategoryAllQuery();
  const yearsData = useGetCpdYearAllQuery();
  const hourTypesData = useGetCpdHourTypeAllQuery();

  const events = eventsData.data?.["hydra:member"] || [];
  const categories = categoriesData.data?.["hydra:member"] || [];
  const years = yearsData.data?.["hydra:member"] || [];
  const hourTypes = hourTypesData.data?.["hydra:member"] || [];

  const isLoading =
    yearsData.isLoading ||
    hourTypesData.isLoading ||
    eventsData.isLoading ||
    categoriesData.isLoading;

  const prepareRowToForm = (row) => {
    const data = { ...row };
    row.hours.forEach((req) => {
      data["hour_" + req.type_id] = req.value;
    });

    return data;
  };

  const getColumns = ({ hourTypes }) => {
    const hts = hourTypes
      .filter((ht) => ht.useInSubsidization)
      .map((ht) => {
        return {
          field: "hour_" + ht.id,
          flex: 1,
          headerName: ht.title,
          renderCell: (params) => {
            const index = params.row.hours.findIndex(
              (h) => h.type_id === ht.id
            );
            if (index > -1) {
              return <>{params.row.hours[index].value}</>;
            }
            return <></>;
          },
        };
      });

    const columns = [
      {
        field: "outOfList",
        flex: 1,
        headerName: "Volná",
        renderCell: (params) => {
          if (params.row.outOfList) {
            return <>ANO</>;
          }
          return <>NE</>;
        },
      },
      {
        field: "code",
        flex: 1,
        headerName: "Kód",
      },
      {
        field: "title",
        flex: 2,
        headerName: "Název",
      },
      {
        field: "organizator",
        flex: 2,
        headerName: "Pořadatel",
      },
      {
        field: "place",
        flex: 2,
        headerName: "Místo",
      },
      {
        field: "speaker",
        flex: 2,
        headerName: "Přednášející",
      },
      {
        field: "dateFrom",
        flex: 2,
        headerName: "Datum začátku",
        renderCell: (params) => {
          if (params.row.dateFrom) {
            return <>{formatDate(params.row.dateFrom)}</>;
          }
          return <></>;
        },
      },
      {
        field: "categoryId",
        flex: 2,
        headerName: "Kategorie",
        renderCell: (params) => {
          const index = categories.findIndex(
            (h) => params.row.categoryId === h.id
          );
          if (index > -1) {
            return <>{categories[index].title}</>;
          }
          return <></>;
        },
      },
    ]
      .concat(hts)
      .concat([
        {
          field: "yearId",
          flex: 1,
          headerName: "Rok",
          renderCell: (params) => {
            const index = years.findIndex((h) => params.row.yearId === h.id);
            if (index > -1) {
              return <>{years[index].title}</>;
            }
            return <></>;
          },
        },
        {
          field: "actions",
          flex: 1,
          headerName: "Akce",
          disableColumnMenu: true,
          type: "number",
          filterable: false,
          sortable: false,
          renderCell: (params) => {
            return (
              <>
                <IconButton
                  onClick={() => {
                    dispatch(
                      formSet(TYPE_CPD_EVENT, prepareRowToForm(params.row))
                    );
                  }}
                  color="primary"
                  size="small"
                >
                  <Edit fontSize="inherit" />
                </IconButton>
              </>
            );
          },
        },
      ]);

    return columns;
  };

  return (
    <>
      <DataTable
        columns={getColumns({ hourTypes })}
        rows={events}
        autoHeight={true}
        loading={isLoading}
      />
    </>
  );
};

export default CpdEventList;
